import {
  Space,
  Table as AntdTable,
  TableColumnType as AntdTableColumnType,
  TablePaginationConfig,
  TableProps as AntdTableProps,
} from 'antd';
import { useEffect, useState } from 'react';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useExcelExport } from './useExcelExport';

export type TableColumnType<T> = Omit<AntdTableColumnType<T>, 'key'> & {
  key: string;
  exportValue?: (item: T) => string;
};

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_PAGE_SIZE_OPTIONS: string[] = ['10', '20', '50', '100', '200', '500', '1000', '10000'];

export interface TableProps<T> extends Omit<AntdTableProps<T>, 'columns' | 'title'> {
  actions?: React.ReactElement;
  columns: TableColumnType<T>[];
}

export function Table<T extends object>(props: TableProps<T>) {
  const { loading, columns, actions, dataSource, onChange } = props;
  const [visibleData, setVisibleData] = useState<readonly T[]>([]);
  const { exportAction } = useExcelExport(columns, visibleData, 'mb-3');

  useEffect(() => {
    setVisibleData([]);
  }, [dataSource]);

  const handleChange = (
    paging: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorters: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>,
  ) => {
    setVisibleData(extra.currentDataSource);
    onChange && onChange(paging, filters, sorters, extra);
  };

  return (
    <AntdTable<T>
      {...props}
      title={(current) => {
        if (current.length > 0 && visibleData.length === 0) {
          setTimeout(() => setVisibleData(current), 0);
        }

        return (
          <Space>
            {actions}
            {exportAction}
          </Space>
        );
      }}
      scroll={props.scroll === undefined ? { x: 'auto' } : props.scroll}
      pagination={{
        defaultPageSize: DEFAULT_PAGE_SIZE,
        pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
        showSizeChanger: true,
        ...(props.pagination ?? {}),
      }}
      loading={loading}
      onChange={handleChange}
    />
  );
}
