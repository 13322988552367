import { Popover } from 'antd';
import { PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmation } from './Confirmation';

export type DeleteProps = PropsWithChildren<
  {
    onConfirm: () => any;
    noConfirm?: boolean;
  } & (
    | {
        nameKey: string;
      }
    | { name: string }
  )
>;

export function Delete(props: DeleteProps) {
  const { onConfirm, noConfirm, children } = props;
  const { t } = useTranslation();
  const name = (props as any).name ?? t((props as any).nameKey);

  const handleClick = useCallback(
    (visible: boolean) => {
      if (!visible) return;

      if (!noConfirm) {
        confirmation.delete(name, onConfirm);
      } else {
        onConfirm();
      }
    },
    [onConfirm, name, noConfirm],
  );

  return (
    <Popover visible={false} onVisibleChange={handleClick} content={<span>asd</span>} trigger="click">
      {children}
    </Popover>
  );
}
