import * as yup from 'yup';
import { StringSchema } from 'yup';
import { AnyObject, TransformFunction } from 'yup/lib/types';

export * from './password';
export * from './uniqueUser';

export type FieldSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const maxLengthByFieldSize: Record<FieldSize, number> = {
  xs: 20,
  sm: 50,
  md: 250,
  lg: 2000,
  xl: 4000,
};

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const trimStringTransform: TransformFunction<
  StringSchema<string | undefined, AnyObject, string | undefined>
> = (_, originalValue, __) => {
  if (!originalValue) {
    return originalValue;
  }

  return originalValue.trim();
};

function field(this: StringSchema, size: FieldSize = 'md') {
  const maxLength = maxLengthByFieldSize[size];
  return this.transform(trimStringTransform).max(maxLength);
}

yup.addMethod<StringSchema>(yup.string, 'field', field);

yup.addMethod(yup.string, 'field', field);

function address(this: yup.ObjectSchema<any>, required: boolean = true) {
  function create() {
    const field = yup.string().field().nullable();
    return required ? field.required() : field;
  }

  return this.shape({
    street: create(),
    city: create(),
    state: create(),
    zipCode: create(),
  });
}

yup.addMethod(yup.object, 'address', address);

function phone(this: StringSchema): StringSchema<string | undefined, AnyObject, string | undefined> {
  const phone = { message: { key: 'string.matches.phone' } };
  return this.matches(phoneRegExp, phone).nullable().field();
}

yup.addMethod(yup.string, 'phone', phone);

function contact(this: yup.ObjectSchema<any>) {
  return this.shape({
    name: yup.string().field().nullable().required(),
    phone: yup.string().phone().required(),
    email: yup.string().email().nullable().required(),
  });
}

yup.addMethod(yup.object, 'contact', contact);
