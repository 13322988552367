import { Components } from 'react-big-calendar';
import { CalendarEvent } from '../calendarTypes';
import { CustomDateHeader } from './CustomDateHeader';
import { CustomEventWrapper } from './CustomEventWrapper';
import { CustomTimeSlotWrapper } from './CustomTimeSlotWrapper';
import { CustomToolbar } from './CustomToolbar';

export const CUSTOM_CALENDAR_COMPONENTS: Components<CalendarEvent, object> = {
  week: { header: CustomDateHeader },
  timeSlotWrapper: CustomTimeSlotWrapper as any,
  toolbar: CustomToolbar,
  eventWrapper: CustomEventWrapper as any,
};
