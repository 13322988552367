import { Form, notify, Page, spinner } from '@/components';
import { Http } from '@/core';
import { useUserLookupSource } from '@/views';
import * as Yup from 'yup';

const SEND_NOTIFICATION_SCHEMA = Yup.object({
  userId: Yup.string().nullable().required(),
  subject: Yup.string().nullable().field('lg').required(),
  body: Yup.string().nullable().field('xl').required(),
});

type SendNotificationFormValue = Yup.InferType<typeof SEND_NOTIFICATION_SCHEMA>;

const INITIAL_SEND_NOTIFICATION_FORM_VALUE: SendNotificationFormValue = {
  userId: null!,
  body: '',
  subject: '',
};

class DemoHttp extends Http {
  public sendNotification = async (args: SendNotificationFormValue) => {
    await this._axios.post('/api/demo/notification', args);
  };
}

export function DemoPage() {
  const usersDataSource = useUserLookupSource();

  return (
    <Page htmlTitle="Demo">
      <Page.Body>
        <Page.Card
          title="Send notification"
          actionsPosition="bottom"
          actions={
            <Form.Submit uid="send-notification" type="primary">
              Send
            </Form.Submit>
          }
        >
          <Form.Formik
            uid="send-notification"
            validationSchema={SEND_NOTIFICATION_SCHEMA}
            initialValues={INITIAL_SEND_NOTIFICATION_FORM_VALUE}
            onSubmit={(values, formik) => {
              spinner.show();
              new DemoHttp()
                .sendNotification(values)
                .then(() => notify.success('Notification sent!'))
                .then(() => formik.resetForm())
                .finally(spinner.hide);
            }}
          >
            <Form.AsyncSelect name="userId" dataSource={usersDataSource} label="User" required />
            <Form.Input name="subject" label="Subject" required />
            <Form.TextArea name="body" label="Body" required />
          </Form.Formik>
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
