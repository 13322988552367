import { useField } from 'formik';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';
import React, { FC } from 'react';
import { Input } from 'antd';
import type { AutoSizeType } from 'rc-textarea';

export type FormsTextAreaProps = {
  name: string;
  label?: string | false;
  autoSize?: boolean | AutoSizeType;
  required?: boolean;
  className?: string;
  placeholder?: string;
  rows?: number;
};

export const FormsTextArea: FC<FormsTextAreaProps> = ({
  name,
  label: labelOverride,
  autoSize,
  required,
  className,
  placeholder,
  rows,
}: FormsTextAreaProps) => {
  const [field, , helpers] = useField(name);
  const label = useFormLabel(name, labelOverride);

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;

    if (value?.length === 0) {
      helpers.setValue(null);
    } else {
      field.onChange(e);
    }
  }

  return (
    <FormsGroup label={label} name={name} required={required}>
      <Input.TextArea
        {...field}
        value={field.value ?? ''}
        onChange={handleChange}
        autoSize={autoSize}
        className={className}
        placeholder={placeholder}
        rows={rows}
      />
    </FormsGroup>
  );
};

FormsTextArea.defaultProps = {
  autoSize: {
    minRows: 3,
    maxRows: 10,
  },
};
