import { notify, TableColumnType } from '@/components';
import type { Alignment, Cell, Column, TableProperties, Worksheet, Workbook, Buffer } from 'exceljs';
import { meanBy, once } from 'lodash';
import { ReactNode } from 'react';
import { browserDownload } from '@/utils';
import { BrowserRouter } from 'react-router-dom';
import i18next from 'i18next';
import { Provider } from 'react-redux';
import { store } from '@/redux';

const importReactDomServer = once(() => import('react-dom/server'));
const importExcel = once(() => import('exceljs'));

export async function exportToExcel<T>(columnDefinitions: TableColumnType<T>[], data?: readonly T[]) {
  const { Workbook } = await importExcel();
  const workbook = new Workbook();
  const sheet = workbook.addWorksheet('Table');

  const table = await buildTable(columnDefinitions, data || []);
  sheet.addTable(table);

  normalizeColumns(sheet, columnDefinitions);
  generateAndExport(workbook);
}

async function buildTable<T>(
  columnDefinitions: TableColumnType<T>[],
  data: readonly T[],
): Promise<TableProperties> {
  return {
    name: 'Table',
    ref: 'A1',
    headerRow: true,
    style: {
      showRowStripes: true,
      theme: 'TableStyleMedium2',
    },
    columns: getColumns(columnDefinitions),
    rows: await getRows(columnDefinitions, data),
  };
}

function getColumns<T>(columnDefinitions: TableColumnType<T>[]) {
  return columnDefinitions.map((x) => ({
    name: (x.title || '').toString(),
    filterButton: true,
    key: x.key,
  }));
}

async function getRows<T>(columnDefinitions: TableColumnType<T>[], data: readonly T[]) {
  const results = data.map(async (item, index) => await createDataRow(columnDefinitions, item, index));
  return await Promise.all(results);
}

async function createDataRow<T>(columnDefinitions: TableColumnType<T>[], item: T, itemIndex: number) {
  const results = columnDefinitions.map(async (x) => await getStringContent(x, item, itemIndex));
  return Promise.all(results);
}

function normalizeColumns<T>(sheet: Worksheet, columnDefinitions: TableColumnType<T>[]) {
  sheet.columns.forEach((column, index) => normalizeColumn(columnDefinitions, column, index));
}

function normalizeColumn<T>(
  columnsDefinitions: TableColumnType<T>[],
  excelColumn: Partial<Column>,
  index: number,
) {
  const definition = columnsDefinitions[index];
  (excelColumn || []).eachCell!((cell, index) => normalizeColumnCell(cell, index, definition));

  const averageCellContentLength = meanBy(
    (excelColumn.values || []).slice(1).filter((v) => !!v),
    (x) => (x || '').toString().length,
  );
  excelColumn.width = calculateColumnWidth(averageCellContentLength);
}

function normalizeColumnCell<T>(cell: Cell, index: number, definition: TableColumnType<T>) {
  const alignment: Partial<Alignment> = { wrapText: true };

  const headerCellIndex = 1;
  if (index !== headerCellIndex) {
    alignment.horizontal = definition.align;
  }

  cell.alignment = alignment;
}

function calculateColumnWidth(averageCellLength: number) {
  const minimalColumnWidth = 7;
  const multiplier = 1.5;
  return minimalColumnWidth + averageCellLength * multiplier;
}

async function getStringContent<T>(columnDefinition: TableColumnType<T>, item: T, itemIndex: number) {
  if (columnDefinition.exportValue) {
    return columnDefinition.exportValue(item);
  }

  if (columnDefinition.render) {
    const element = (
      <BrowserRouter>
        <Provider store={store}>{columnDefinition.render(item, item, itemIndex) as ReactNode}</Provider>
      </BrowserRouter>
    );
    const { renderToStaticMarkup } = await importReactDomServer();
    const markup = renderToStaticMarkup(element);
    return extractTextContent(markup);
  }

  if (columnDefinition.dataIndex) {
    return (item as any)[columnDefinition.dataIndex.toString()];
  }
}

const generateAndExport = (workbook: Workbook) => {
  workbook.xlsx
    .writeBuffer()
    .then((result: Buffer) => download(result))
    .catch(function (error) {
      console.log(error.message);
      notify.error(i18next.t('errors.exportExcelError'));
    });
};

const download = (xls64: Buffer) => {
  var data = new Blob([xls64], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  browserDownload('Export.xlsx', data);
};

function extractTextContent(html: string) {
  const document = new DOMParser().parseFromString(html, 'text/html');
  return document.documentElement.textContent;
}
