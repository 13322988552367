import { useGetProfileQuery } from '@/redux';
import { useTranslation } from 'react-i18next';

import { UserProfilePanel } from '@/views';
import { Page, Form, Icon } from '@/components';
import { formatters } from '@/core';

export function UserProfilePage() {
  const { t } = useTranslation();
  const { data } = useGetProfileQuery();

  if (!data) {
    return null;
  }

  const title = formatters.name(data);

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          title={title}
          actionsPosition="bottom"
          icon={<Icon type="user" />}
          color="#00bfa5"
          actions={
            <Form.Submit type="primary" uid="user-profile">
              {t('save')}
            </Form.Submit>
          }
        >
          <UserProfilePanel />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
