import moment from 'moment';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});
moment.locale('en');

require('./App');
require('./assets/styles/index.scss');
