import { defaultInputNumberProps } from '@/components/Forms/FormsNumber';
import { Button, Col, InputNumber, Row, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SEPARATOR = '///';

const fieldStyles: CSSProperties = {
  marginBottom: 8,
  display: 'inline-block',
  width: '140px',
};

export function _NumberRangeFilter(props: FilterDropdownProps) {
  const { t } = useTranslation();
  const { setSelectedKeys, visible, confirm } = props;

  const [from, setFrom] = useState<number | undefined>(undefined);
  const [to, setTo] = useState<number | undefined>(undefined);

  function reset() {
    setFrom(undefined);
    setTo(undefined);
  }

  useEffect(() => {
    if (visible) {
      return;
    }

    const exists = !!from || !!to;
    const fromValue = from ?? '';
    const toValue = to ?? '';
    const result = fromValue + SEPARATOR + toValue;

    setSelectedKeys(exists ? [result] : []);
    confirm({ closeDropdown: false });

    // eslint-disable-next-line
  }, [visible]);

  return (
    <div>
      <Space style={{ padding: 8 }}>
        <div>
          <InputNumber
            {...defaultInputNumberProps}
            placeholder={`From`}
            value={from}
            onChange={(value) => setFrom(value)}
            style={{ ...fieldStyles, marginRight: '8px' }}
          />
          <InputNumber
            {...defaultInputNumberProps}
            placeholder={`To`}
            value={to}
            onChange={(value) => setTo(value)}
            style={fieldStyles}
          />
        </div>
      </Space>
      <Row justify="space-between" style={{ padding: 8 }}>
        <Col>
          <Button onClick={reset} size="small" type="link">
            {t('reset')}
          </Button>
        </Col>
        <Col>
          <Button onClick={() => confirm({ closeDropdown: true })} size="small" type="primary">
            {t('ok')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function onFilter<T>(filterValue: string | number | boolean, recordValue: T) {
  const [start, end] = filterValue.toString().split(SEPARATOR);
  const startCondition = !start || +recordValue >= +start;
  const endCondition = !end || +recordValue <= +end;

  return startCondition && endCondition;
}

export const NumberRangeFilter = Object.assign(_NumberRangeFilter, {
  onFilter,
});
