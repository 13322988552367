import { Icon } from './Icon';
import { Modal, Row, Tree as AntdTree, TablePaginationConfig } from 'antd';

export const DEFAULT_PAGING: TablePaginationConfig = {
  pageSize: 20,
  current: 1,
};

export const StyledRow = Object.assign({}, Row);
StyledRow.defaultProps = {
  ...StyledRow.defaultProps,
  gutter: [10, 10],
};

export const FieldRow = Object.assign({}, Row);
FieldRow.defaultProps = {
  ...FieldRow.defaultProps,
  gutter: [20, 10],
};

export const Tree = Object.assign({}, AntdTree);
Tree.defaultProps = {
  ...Tree.defaultProps,
  switcherIcon: <Icon type="arrow-down" />,
};

Modal.defaultProps = {
  width: 620,
};
