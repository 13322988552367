import { useTranslation } from 'react-i18next';

import { UserAddPanel } from '@/views';
import { Auth, Form, Icon, Page } from '@/components';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const useHandleSubmitted = () => {
  const navigate = useNavigate();
  return useCallback(() => navigate('/users'), [navigate]);
};

function _UserAddPage() {
  const { t } = useTranslation();
  const handleSubmitted = useHandleSubmitted();

  return (
    <Page htmlTitle={t('users.add.title')}>
      <Page.Paths>
        <Page.Path href="/users">{t('users.title')}</Page.Path>
        <Page.Path>{t('users.add.title')}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          icon={<Icon type="user" />}
          color="rgb(68, 162, 180)"
          title={t('users.add.title')}
          actions={
            <Form.Submit onSubmitted={handleSubmitted} type="primary" uid="user-add">
              {t('save')}
            </Form.Submit>
          }
          actionsPosition="bottom"
        >
          <UserAddPanel />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}

export const UserAddPage = Auth.Banner('ADMIN')(_UserAddPage);
