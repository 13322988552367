import * as Yup from 'yup';
import moment from 'moment';
import { FieldRow, Form } from '@/components';
import { CalendarEventType } from '@/redux';
import { enumValues } from '@/utils';
import { TimeUnit, Weekday, addWeekday, convertTimeUnitsToMinutes } from '@/core';
import { useCallback, useEffect, useMemo } from 'react';
import { useUserLookupSource } from '@/views/Users.Common';
import { Col } from 'antd';
import { useFormikContext } from 'formik';

const SCHEMA = Yup.object({
  type: Yup.mixed<CalendarEventType>()
    .oneOf([...enumValues(CalendarEventType), null])
    .required(),
  start: Yup.mixed<moment.Moment>().required(),
  startDate: Yup.mixed<moment.Moment>()
    .test(
      'gte-today',
      { key: 'gteToday' },
      async (value) => !value || moment(value).isSameOrAfter(moment().startOf('day').utc(true)),
    )
    .required(),
  end: Yup.mixed<moment.Moment>()
    .defined()
    .test(
      'gt-start',
      { key: 'endLteStart' },
      async (value, { parent: { start } }) => !start || !value || moment(value).isAfter(moment(start)),
    )
    .when('type', (type, schema) => (type === 'OneTime' ? schema.required() : schema)),
  endDate: Yup.mixed<moment.Moment>().when('type', (type, schema) =>
    type === 'OneTime' ? schema.required() : schema,
  ),
  name: Yup.string().field().nullable().required(),
  assignees: Yup.array(Yup.string().required()).required(),
  durationMinutes: Yup.number()
    .min(1)
    .max(24 * 60 - 1)
    .nullable()
    .defined()
    .when('type', (type, schema) => {
      return type === 'Weekday' || type === 'Interval' ? schema.required() : schema;
    }),
  weekdays: Yup.array(Yup.mixed<Weekday>().required())
    .required()
    .when('type', (type, schema) => (type === 'Weekday' ? schema.min(1) : schema)),
  intervalTimeUnitCount: Yup.number()
    .min(1)
    .nullable()
    .defined()
    .when('type', (type, schema) => (type === 'Interval' ? schema.required() : schema)),
  intervalTimeUnit: Yup.mixed<TimeUnit>()
    .nullable()
    .defined()
    .when('type', (type, schema) => (type === 'Interval' ? schema.required() : schema)),
});

type CreateCalendarEventFormValueInternal = Yup.InferType<typeof SCHEMA>;
export type CreateCalendarEventFormValue = Omit<
  CreateCalendarEventFormValueInternal,
  'startDate' | 'endDate' | 'intervalTimeUnitCount' | 'intervalTimeUnit'
> & { intervalMinutes: number | null };

export interface CreateCalendarEventFormProps {
  initialValues?: Pick<CreateCalendarEventFormValue, 'start' | 'end'>;
  onSubmit: (value: CreateCalendarEventFormValue) => any;
}

function useInitialValues(props: CreateCalendarEventFormProps, type: CalendarEventType = 'OneTime') {
  const { start, end } = props.initialValues ?? {};
  return useMemo<CreateCalendarEventFormValueInternal>(
    () => ({
      start: start ? moment(start).utc() : null!,
      startDate: start ? moment(start).utcOffset(moment().utcOffset()).startOf('day').utc(true) : null!,
      end: end ? moment(end).utc() : null!,
      endDate: end ? moment(end).utcOffset(moment().utcOffset()).startOf('day').utc(true) : null!,
      type,
      assignees: [],
      durationMinutes: null,
      intervalTimeUnitCount: null,
      intervalTimeUnit: null,
      name: '',
      weekdays: [],
    }),
    [start, end, type],
  );
}

function Content({ initialValues }: { initialValues: ReturnType<typeof useInitialValues> }) {
  const userLookupSource = useUserLookupSource();
  const { values, setFieldValue, setValues } = useFormikContext<CreateCalendarEventFormValueInternal>();
  const { startDate, endDate, type } = values;

  useEffect(() => {
    setFieldValue('start', null!, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    setFieldValue('end', null!, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  useEffect(() => {
    const intervalTimeUnit = type === 'Interval' ? 'Minute' : null;
    setValues({
      ...initialValues,
      endDate: type === 'OneTime' ? values.endDate : null!,
      end: type === 'OneTime' ? values.end : null!,
      name: values.name,
      assignees: [...values.assignees],
      type: values.type,
      intervalTimeUnit,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      <Form.EnumRadio name="type" type={CalendarEventType} label={false} required />
      <Form.Input name="name" required />
      <FieldRow>
        <Col span={12}>
          <Form.Date name="startDate" required />
        </Col>
        <Col span={12}>
          <Form.Time name="start" date={startDate} disabled={!startDate} required />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={12}>
          <Form.Date name="endDate" required={type === 'OneTime'} allowClear />
        </Col>
        <Col span={12}>
          {type === 'OneTime' && <Form.Time name="end" date={endDate} disabled={!endDate} required />}
        </Col>
      </FieldRow>
      <Form.AsyncSelect name="assignees" mode="multiple" dataSource={userLookupSource} />
      {(type === 'Interval' || type === 'Weekday') && <Form.Number name="durationMinutes" required />}
      {type === 'Interval' && (
        <FieldRow>
          <Col span={12}>
            <Form.Number name="intervalTimeUnitCount" required />
          </Col>
          <Col span={12}>
            <Form.EnumSelect name="intervalTimeUnit" type={TimeUnit} required />
          </Col>
        </FieldRow>
      )}
      {type === 'Weekday' && <Form.EnumSelect name="weekdays" mode="multiple" type={Weekday} required />}
    </>
  );
}

function applyTimezoneOffsetToWeekdays(start: moment.Moment, weekdays: Weekday[]) {
  const offset = moment().utcOffset();
  start = start.clone().utc();
  const startDate = start.clone().startOf('date');
  const localDate = start.clone().utcOffset(offset).add(offset, 'minutes').utc(true).startOf('date');

  if (localDate.isSame(startDate)) {
    return weekdays;
  }

  if (localDate.isAfter(startDate)) {
    return weekdays.map((weekday) => addWeekday(weekday, -1));
  }

  return weekdays.map((weekday) => addWeekday(weekday, 1));
}

function convertEnd(value: CreateCalendarEventFormValueInternal) {
  const { endDate, end, type } = value;

  if (type === 'OneTime') {
    return end;
  }

  if (endDate == null) return null!;

  return moment()
    .startOf('date')
    .year(endDate.year())
    .month(endDate.month())
    .date(endDate.date())
    .add(1, 'day');
}

function useSubmit(props: CreateCalendarEventFormProps) {
  const { onSubmit } = props;

  return useCallback(
    (value: CreateCalendarEventFormValueInternal) => {
      const { intervalTimeUnit, intervalTimeUnitCount, startDate, endDate, ...formValue } = value;
      const intervalMinutes =
        intervalTimeUnit != null && intervalTimeUnitCount != null
          ? convertTimeUnitsToMinutes(intervalTimeUnit, intervalTimeUnitCount)
          : null;

      const end = convertEnd(value);
      const weekdays = applyTimezoneOffsetToWeekdays(value.start, value.weekdays);

      return onSubmit({ ...formValue, intervalMinutes, weekdays, end });
    },
    [onSubmit],
  );
}

export function CreateCalendarEventForm(props: CreateCalendarEventFormProps) {
  const onSubmit = useSubmit(props);
  const initialValues = useInitialValues(props);

  return (
    <Form.Formik
      uid="create-calendar-event"
      i18nKeyPrefix="calendars.create"
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={SCHEMA}
    >
      <Content initialValues={initialValues} />
    </Form.Formik>
  );
}
