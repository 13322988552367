import { Http, Period } from '@/core';
import qs from 'qs';
import {
  CalendarEvent,
  CancelCalendarEventAppearanceRequest,
  CancelCalendarRecurrentEventLineRequest,
  CreateCalendarEventRequest,
  MoveCalendarEventRequest,
  ReassignCalendarEventRequest,
} from './calendarTypes';

class CalendarHttp extends Http {
  constructor() {
    super();

    this._axios.defaults.baseURL = '/api/calendar';
  }

  public async getAll(args: Period<string>) {
    const query = qs.stringify(args);
    const url = `event?${query}`;
    const response = await this._axios.get<CalendarEvent[]>(url);
    return response.data;
  }

  public create = async (args: CreateCalendarEventRequest) => {
    await this._axios.post('event', args);
  };

  public move = async (args: MoveCalendarEventRequest) => {
    await this._axios.put('event/start', args);
  };

  public cancelAppearance = async (args: CancelCalendarEventAppearanceRequest) => {
    await this._axios.put('event/appearance/cancel', args);
  };

  public cancelLine = async (args: CancelCalendarRecurrentEventLineRequest) => {
    await this._axios.put('event/line/cancel', args);
  };

  public reassign = async (args: ReassignCalendarEventRequest) => {
    await this._axios.put('event/assignees', args);
  };
}

export const calendarHttp = new CalendarHttp();
