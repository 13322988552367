import { Period, UserLookupDto, Weekday } from '@/core';
import { EnumObjectValue, enumObject } from '@/utils';

export interface CalendarEvent {
  id: string | null;
  name: string;
  assignees: UserLookupDto[];
  recurrentEventId?: string;
  start: string;
  end: string;
  movedFrom?: Period<string>;
}

export const CalendarEventType = enumObject('CalendarEventType', ['OneTime', 'Weekday', 'Interval'] as const);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CalendarEventType = EnumObjectValue<typeof CalendarEventType>;

export interface CreateCalendarEventRequest {
  type: CalendarEventType;
  start: string;
  name: string;
  assignees: string[];
  end: string | null;
  durationMinutes: number | null;
  weekdays: Weekday[];
  intervalMinutes: number | null;
}

export interface MoveCalendarEventRequest {
  id?: string;
  recurrentEventId?: string;
  start: string;
  newStart: string;
}

export interface CancelCalendarEventAppearanceRequest {
  id?: string;
  recurrentEventId?: string;
  eventStart: string;
}

export interface CancelCalendarRecurrentEventLineRequest {
  recurrentEventId: string;
  since: string;
}

export interface CancelCalendarRecurrentEventLineRequest {
  recurrentEventId: string;
  since: string;
}

export interface ReassignCalendarEventRequest {
  id?: string;
  recurrentEventId?: string;
  eventStart: string;
  assignees: string[];
  thisAndBeyond: boolean;
}
