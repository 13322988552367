import { FC } from 'react';
import { color } from '@/utils';
import { useAvatar } from './useAvatar';
import { Avatar as AntdAvatar, Image } from 'antd';
import { formatters } from '@/core';

export interface AvatarProps {
  alt: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | number;
  reference?: string;
  className?: string;
  zoom?: boolean;
}

const sizes: Record<string, number> = {
  xs: 30,
  sm: 40,
  md: 70,
  lg: 150,
};

export const Avatar: FC<AvatarProps> = (props) => {
  const { alt, size = 'md', reference, className, zoom } = props;
  const fallback = formatters.initials(alt).toUpperCase();
  const bgColor = color.random('lighten-1', alt.toUpperCase());
  const { url, notFound } = useAvatar(reference);
  const sizePx = typeof size === 'number' ? size : sizes[size];

  if (notFound) {
    return (
      <AntdAvatar
        gap={Math.round(sizePx / 4)}
        size={sizePx}
        className={className}
        style={{ backgroundColor: bgColor, fontSize: sizePx, lineHeight: sizePx }}
      >
        {fallback}
      </AntdAvatar>
    );
  }

  const src = zoom ? <Image src={url} /> : url;
  return <AntdAvatar size={sizePx} className={className} src={src} />;
};
