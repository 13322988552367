import { v4 as uuid } from 'uuid';

export type EventSubscriber = (payload: any, type: string) => any;
export type EventSubscriberDispose = () => any;
type EventData = { type: string; payload: any; emitterId: string };

const EMITTER_ID = uuid();
const BROADCAST_EVENT_STORAGE_KEY_PREFIX = 'broadcast-event-';
const subscribers: Record<string, EventSubscriber[]> = {};

function publishBroadcast(type: string, payload: any = null, receiveOnHost: boolean = false) {
  const eventKey = `${BROADCAST_EVENT_STORAGE_KEY_PREFIX}${type}`;
  const eventData: EventData = { type, payload, emitterId: EMITTER_ID };
  localStorage.setItem(eventKey, JSON.stringify(eventData));
  localStorage.removeItem(eventKey);

  if (receiveOnHost) {
    notifySubscribers(type, payload);
  }
}

function notifySubscribers(type: string, payload: any) {
  const subscriberCallbacks = subscribers[type] ?? [];
  subscriberCallbacks.forEach((subscriber) => subscriber(payload, type));
}

/**
 * Adds event subscriber
 * @param type Event type
 * @param subscriber Event subscriber
 * @returns {EventSubscriberDispose} call this function to unsubscribe from event
 */
function subscribe(type: string, subscriber: EventSubscriber): EventSubscriberDispose {
  subscribers[type] = subscribers[type] ?? [];
  subscribers[type].push(subscriber);

  return () => {
    const index = subscribers[type].indexOf(subscriber);
    subscribers[type].splice(index, 1);
  };
}

/**
 * Publish cross tab event.
 * @param type Event type
 * @param payload Event payload
 * @param receiveOnHost If true - host broadcast subscribers would be notified
 */
function publish(type: string, payload: any, broadcast: true, receiveOnHost: boolean): void;

/**
 * Publish event for subscribers in current tab
 * @param type Event type
 * @param payload Event payload
 */
function publish(type: string, payload: any): void;
function publish(type: string, payload?: any, broadcast?: true, receiveOnHost?: boolean): void {
  if (broadcast) {
    publishBroadcast(type, payload, receiveOnHost);
  } else {
    notifySubscribers(type, payload);
  }
}

window.addEventListener('storage', (event) => {
  if (!event.key || !event.key.startsWith(BROADCAST_EVENT_STORAGE_KEY_PREFIX)) {
    return;
  }

  if (event.newValue == null) {
    return;
  }

  const eventData: EventData = JSON.parse(event.newValue);
  if (eventData.emitterId === EMITTER_ID) {
    return;
  }

  notifySubscribers(eventData.type, eventData.payload);
});

export const eventBus = {
  publish,
  subscribe,
};
