import { AttachProps } from './attachProps';
import { MAX_FILE_SIZE } from '@/platform';
import { formatters } from '@/core';
import { Button } from 'antd';
import React, { useRef, useState } from 'react';
import { notify } from '../Notify';
import { isEmpty } from 'lodash';
import { AttachList } from './AttachList';
import { useTranslation } from 'react-i18next';
import { AttachError } from './AttachError';
import { Icon } from '../Icon';
import styles from './Attach.module.scss';

function validate(file: File, props: AttachProps): boolean {
  if (file.size > MAX_FILE_SIZE) {
    notify.error.t('uploadFile.fileTooLarge', {
      maxSize: formatters.fileSize(MAX_FILE_SIZE),
    });
    return false;
  }

  if (props.accept != null) {
    const types = props.accept?.split(/,| |, /);
    if (!types?.includes(file.type)) {
      notify.error.t('uploadFile.invalidType');
      return false;
    }
  }

  return true;
}

function mapToArray(fileList: FileList) {
  const files: File[] = [];

  for (let i = 0; i < fileList.length; i++) {
    files.push(fileList.item(i)!);
  }

  return files;
}

export function Attach(props: AttachProps) {
  const { store, readonly, className, accept } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [uploading, setUploading] = useState(false);
  const { t } = useTranslation();

  async function upload(filesList: FileList) {
    let files = mapToArray(filesList);
    files = files.filter((file) => validate(file, props));
    return isEmpty(files) ? Promise.resolve() : store(files);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUploading(true);
    upload(event.target.files!).finally(() => setUploading(false));
  }

  function openDialog() {
    inputRef.current!.click();
  }

  return (
    <div className={className}>
      {!readonly && (
        <>
          <input
            ref={inputRef}
            accept={accept}
            style={{ height: 0, width: 0 }}
            value=""
            onChange={handleChange}
            type="file"
            readOnly={readonly}
          />

          <Button
            loading={uploading}
            icon={<Icon type="paperclip" />}
            onClick={openDialog}
            className={styles.btn}
          >
            {t('attach.btnText')}
          </Button>
        </>
      )}
      <AttachError {...props} />
      <AttachList {...props} />
    </div>
  );
}
