import { useTranslation } from 'react-i18next';
import { CodedException } from '@/core';
import { AttachProps } from './attachProps';

export function AttachError(props: AttachProps) {
  const { applyFailed, storeFailed, contentFailed, fetchFailed, byIdFailed } = props.value;
  const { t } = useTranslation();

  const errors = applyFailed
    ? [applyFailed]
    : storeFailed?.innerErrors.length > 0
    ? storeFailed.innerErrors
    : contentFailed
    ? [contentFailed]
    : fetchFailed
    ? [fetchFailed]
    : byIdFailed
    ? [byIdFailed]
    : [];

  if (errors.length === 0) return null;
  const coded = errors.map((error) => CodedException.from(error));

  return (
    <div className="text-error">
      {coded.map((error, index) => (
        <div key={index}>{t(`errors.coded.${error.code}`, error.payload)}</div>
      ))}
    </div>
  );
}
