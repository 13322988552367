import { FieldRow, Form } from '@/components';
import { Button, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Contact } from '@/core';

export interface FormsContactsProps {
  name: string;
  required: boolean;
  deletable: boolean;
  onDelete: () => any;
}

export const INITIAL_CONTACT: Contact = {
  name: null!,
  phone: null!,
  email: null!,
};

export const FormsContacts = ({
  name,
  required,
  deletable: showCancelAdd,
  onDelete: cancelAddContact,
}: FormsContactsProps) => {
  return (
    <>
      <FieldRow>
        <Col span={12}>
          <Form.Input name={`${name}.name`} required={required} />
        </Col>
        <Col span={12}>
          {showCancelAdd && <Button type="link" icon={<CloseOutlined />} onClick={cancelAddContact} />}
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={12}>
          <Form.Phone name={`${name}.phone`} required={required} />
        </Col>
        <Col span={12}>
          <Form.Input name={`${name}.email`} required={required} />
        </Col>
      </FieldRow>
    </>
  );
};
