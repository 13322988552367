const NAME = Symbol('name');
const VALUES = Symbol('values');

export type EnumObject<TEnum extends Readonly<string[]>> = {
  [key in TEnum[number]]: key;
};

export type EnumValue<TEnum extends Readonly<string[]> = any> = keyof EnumObject<TEnum>;
export type EnumObjectValue<
  TEnumObject extends EnumObject<TEnum>,
  TEnum extends Readonly<string[]> = any,
> = keyof TEnumObject;

export function enumObject<TEnum extends Readonly<string[]>>(name: string, values: TEnum): EnumObject<TEnum> {
  const result: any = {
    [NAME]: name,
    [VALUES]: values,
    ...values.reduce((acc, cur) => Object.assign(acc, { [cur]: cur }), {} as any),
  };

  return result;
}

export function enumValues<T extends Readonly<string[]>>(enumObject: EnumObject<T>): EnumValue<T>[] {
  return (enumObject as any)[VALUES];
}

export function enumName<T extends Readonly<string[]>>(enumObject: EnumObject<T>): string {
  return (enumObject as any)[NAME];
}
