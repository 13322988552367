import { Button } from 'antd';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './UserAvatarEdit.module.scss';

export interface PickerProps {
  onSelected: (file: File) => any;
}

function usePickerState(props: PickerProps) {
  const { onSelected } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSelected = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.currentTarget.files![0];
      onSelected(file);
    },
    [onSelected],
  );

  const open = useCallback(() => inputRef.current!.click(), []);

  return { inputRef, open, onSelected: handleSelected };
}

export function Picker(props: PickerProps) {
  const { inputRef, open, onSelected } = usePickerState(props);
  const { t } = useTranslation();

  return (
    <div>
      <input
        ref={inputRef}
        className={styles.pickerInput}
        type="file"
        accept="image/*"
        onChange={onSelected}
        value={''}
      />

      <div className="text-center">
        <Button type="link" onClick={open}>
          {t('avatar.edit.text')}
        </Button>
      </div>
    </div>
  );
}
