import styles from './Calendar.module.scss';
import moment from 'moment';
import { DateRange } from 'react-big-calendar';
import { Calendar, CalendarProps, MoveCalendarEventPayload } from './Calendar';
import { useCallback, useMemo, useState } from 'react';
import {
  CalendarEvent,
  ReassignCalendarEventRequest,
  useCancelCalendarEventAppearanceMutation,
  useCancelCalendarRecurrentEventLineMutation,
  useCreateCalendarEventMutation,
  useGetCalendarEventsQuery,
  useMoveCalendarEventMutation,
  useReassignCalendarEventMutation,
} from '@/redux';
import { array, useBindArgs } from '@/utils';
import { CreateCalendarEventModal } from './Create';
import { Button } from 'antd';
import { CreateCalendarEventFormValue } from './Create/CreateCalendarEventForm';
import { notify } from '@/components';
import { CalendarEventDetailsModal } from './Details';

const INITIAL_RANGE: DateRange = {
  start: moment().startOf('week').toDate(),
  end: moment().endOf('week').startOf('date').add(1, 'day').toDate(),
};

function useEvents(range: DateRange) {
  const { currentData: events = array.empty<CalendarEvent>() } = useGetCalendarEventsQuery({
    start: range.start.toISOString(),
    end: range.end.toISOString(),
  });

  return useMemo<CalendarProps['events']>(
    () => events.map((x) => ({ start: new Date(x.start), end: new Date(x.end), original: x })),
    [events],
  );
}

function useCreate(onCreated: () => any) {
  const [create] = useCreateCalendarEventMutation();
  return useCallback(
    (value: CreateCalendarEventFormValue) =>
      create({ ...value, start: value.start.toISOString(), end: value.end?.toISOString() })
        .unwrap()
        .then(() => notify.success.t('calendars.event.created'))
        .then(onCreated),
    [create, onCreated],
  );
}

function useCancelAppearance(onCancelled: () => any) {
  const [cancel] = useCancelCalendarEventAppearanceMutation();
  return useCallback(
    (event: CalendarEvent) => {
      cancel({
        id: event.id ?? undefined,
        recurrentEventId: event.recurrentEventId,
        eventStart: event.movedFrom?.start ?? event.start,
      })
        .unwrap()
        .then(() => notify.success.t('calendars.event.cancelled'));

      onCancelled();
    },
    [cancel, onCancelled],
  );
}

function useCancelLine(onCancelled: () => any) {
  const [cancel] = useCancelCalendarRecurrentEventLineMutation();

  return useCallback(
    (event: CalendarEvent) => {
      cancel({
        recurrentEventId: event.recurrentEventId!,
        since: event.start,
      })
        .unwrap()
        .then(() => notify.success.t('calendars.event.cancelled'));

      onCancelled();
    },
    [cancel, onCancelled],
  );
}

function useMove() {
  const [move] = useMoveCalendarEventMutation();

  return useCallback(
    (payload: MoveCalendarEventPayload) => {
      const { start, movedFrom, id, recurrentEventId } = payload.event.original;
      return move({
        id: id ?? undefined,
        recurrentEventId,
        start: movedFrom?.start ?? start,
        newStart: payload.start.toISOString(),
      })
        .unwrap()
        .then(() => notify.success.t('calendars.event.moved'));
    },
    [move],
  );
}

function useReassign(onDone: () => any) {
  const [reassign] = useReassignCalendarEventMutation();

  return useCallback(
    (event: CalendarEvent, assignees: string[], thisAndBeyond: boolean) => {
      const { id, recurrentEventId, movedFrom, start } = event;
      const request: ReassignCalendarEventRequest = {
        id: id ?? undefined,
        recurrentEventId,
        assignees,
        eventStart: movedFrom?.start ?? start,
        thisAndBeyond,
      };

      onDone();
      return reassign(request)
        .unwrap()
        .then(() => notify.success.t('calendars.event.reassigned'));
    },
    [reassign, onDone],
  );
}

export function CalendarPanel() {
  const [range, setRange] = useState(INITIAL_RANGE);
  const events = useEvents(range);
  const [isCreateShown, setShowCreate] = useState<DateRange | boolean>();
  const resetShowCreate = useBindArgs(setShowCreate, undefined);
  const showEmptyCreate = useBindArgs(setShowCreate, true);
  const create = useCreate(resetShowCreate);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>();
  const onCloseSelectedEvent = useBindArgs(setSelectedEvent, undefined);
  const onCancelAppearance = useCancelAppearance(onCloseSelectedEvent);
  const onCancelLine = useCancelLine(onCloseSelectedEvent);
  const onMove = useMove();
  const onReassign = useReassign(onCloseSelectedEvent);

  const initialCreateValues = useMemo(
    () =>
      typeof isCreateShown === 'object'
        ? { start: moment(isCreateShown.start).utc(), end: moment(isCreateShown.end).utc() }
        : undefined,
    [isCreateShown],
  );

  return (
    <>
      <Calendar
        range={range}
        onRangeChange={setRange}
        events={events}
        onSelectSlot={setShowCreate}
        onDoubleClick={(e) => setSelectedEvent(e.original)}
        onMove={onMove}
      />
      <Button type="primary" onClick={showEmptyCreate} className={styles.createButton}>
        +
      </Button>
      {isCreateShown && (
        <CreateCalendarEventModal
          initialValues={initialCreateValues}
          onSubmit={create}
          onCancel={resetShowCreate}
        />
      )}
      {selectedEvent && (
        <CalendarEventDetailsModal
          event={selectedEvent}
          onCancel={onCloseSelectedEvent}
          onCancelAppearance={onCancelAppearance}
          onCancelLine={onCancelLine}
          onReassign={onReassign}
        />
      )}
    </>
  );
}
