import { useMemo } from 'react';
import { useAppListState } from '@/core';
import { NotificationListField, NotificationListItemState } from '@webinex/wispo';
import { TablePaginationConfig } from 'antd';
import { useLocalizeNotification } from '../Notifications';

const INCLUDE = [NotificationListField.Items, NotificationListField.TotalMatch];

const useLocalizedNotifications = (items: NotificationListItemState[]): NotificationListItemState[] => {
  const localize = useLocalizeNotification();
  return useMemo(() => items.map(localize), [localize, items]);
};

export function useNotificationsListData(paging: TablePaginationConfig) {
  const { current, pageSize } = paging;

  const skip = (current! - 1) * pageSize!;

  const { isPending, items, markRead, totalMatch } = useAppListState({
    include: INCLUDE,
    take: pageSize!,
    skip: skip,
  });

  const localized = useLocalizedNotifications(items);
  return { isPending, items: localized, markRead, totalMatch };
}
