import {
  ChangePasswordDto,
  UpdateProfileDto,
  UpdateUserDto,
  UserContextDto,
  UserDto,
  usersHttp,
  UserInviteDto,
  openid,
  UserLookupDto,
} from '@/core';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { api } from '../api';
import { rtkq, RtkqRequest } from '../rtkq';

export type UserState = UserDto;
export type UserLookupState = UserLookupDto;
export type UserContextState = UserContextDto;
export type UpdateProfileArgs = UpdateProfileDto & { id: string };
export type UpdateUserArgs = UpdateUserDto;
export type ChangePasswordArgs = ChangePasswordDto;
export type UserInviteArgs = UserInviteDto;

function selectContext(args: BaseQueryApi): UserContextState {
  const state = args.getState() as any;
  return usersApi.endpoints.getContext.select()(state)?.data!;
}

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getContext: build.query<UserContextState, RtkqRequest<void>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => usersHttp.context());
      },
      providesTags: (result) => [{ type: 'user', id: result?.id ?? 'none' }],
    }),

    getProfile: build.query<UserState, RtkqRequest<void>>({
      queryFn: async (args, api) => {
        const id = selectContext(api).id;
        return await rtkq(args).exec(() => usersHttp.get(id));
      },
      providesTags: (user) => [{ type: 'user', id: user?.id ?? 'none' }],
    }),

    updateProfile: build.mutation<void, RtkqRequest<UpdateProfileArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => usersHttp.updateProfile(args));
      },
      invalidatesTags: (_, __, args) => [{ type: 'user', id: args.id }],
    }),

    getUsers: build.query<UserState[], RtkqRequest<void>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => usersHttp.getAll());
      },
      providesTags: (result = []) => [
        { type: 'user', id: 'list' },
        ...result.map(({ id }) => ({ type: 'user' as const, id })),
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        for (const user of data) {
          dispatch(usersApi.util.updateQueryData('getUser', { id: user.id }, () => user));
        }
      },
    }),

    getUserLookups: build.query<UserLookupState[], RtkqRequest<void>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => usersHttp.getLookups());
      },
      providesTags: (result = []) => [
        { type: 'user', id: 'list' },
        ...result.map(({ id }) => ({ type: 'user' as const, id })),
      ],
    }),

    getUser: build.query<UserState, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        const { id } = args;
        return await rtkq(args).exec(() => usersHttp.get(id));
      },
      providesTags: (_, __, { id }) => [{ type: 'user', id }],
    }),

    updateUser: build.mutation<void, RtkqRequest<UpdateUserArgs>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => usersHttp.update(args));
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'user', id }],
    }),

    changePassword: build.mutation<void, RtkqRequest<ChangePasswordArgs>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => usersHttp.changePassword(args));
      },
    }),

    changeActive: build.mutation<void, RtkqRequest<{ id: string; value: boolean }>>({
      queryFn: async (args, api) => {
        const { id } = selectContext(api);
        return rtkq(args).exec(async () => {
          await usersHttp.changeActive(args.id, args.value);
          id === args.id && (await openid.logout());
        });
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'user', id }],
    }),

    unlockUser: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        const { id } = args;
        return rtkq(args).exec(() => usersHttp.unlock(id));
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'user', id }],
    }),

    inviteUser: build.mutation<void, RtkqRequest<UserInviteArgs>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => usersHttp.invite(args));
      },
      invalidatesTags: [{ type: 'user', id: 'list' }],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useGetContextQuery,
  useGetUsersQuery,
  useLazyGetUserLookupsQuery,
  useGetUserQuery,
  useChangePasswordMutation,
  useChangeActiveMutation,
  useUnlockUserMutation,
  useInviteUserMutation,
  useUpdateUserMutation,
  useUpdateProfileMutation,
} = usersApi;
