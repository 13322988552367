import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SignUpDto, CodedException } from '@/core';
import { Form } from '@/components';
import { Alert } from 'antd';

const schema = Yup.object().shape({
  email: Yup.string().email().uniqueUser().field().nullable().required(),
  firstName: Yup.string().field().nullable().required(),
  lastName: Yup.string().field().nullable().required(),
  password: Yup.string().password().nullable().required(),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], { key: 'signup.passwordConfirmationMatchError' })
    .nullable()
    .required(),
});

export interface SignUpFormProps {
  onSubmit: (value: SignUpDto) => any;
  error?: CodedException;
}

const INITIAL_VALUE: SignUpDto & { passwordConfirmation: string } = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  passwordConfirmation: '',
};

export function SignUpForm({ onSubmit, error }: SignUpFormProps) {
  const [t, i18n] = useTranslation();
  const errorI18nKey = error?.code && `errors.coded.${error.code}`;
  const errorText = error && i18n.exists(errorI18nKey!) ? t(errorI18nKey!) : error?.defaultMessage;

  return (
    <Form.Formik
      uid="signup"
      i18nKeyPrefix="signup"
      initialValues={INITIAL_VALUE}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Form.Input name="email" type="email" required />
          <Form.Input name="firstName" required />
          <Form.Input name="lastName" required />
          <Form.Input name="password" type="password" required />
          <Form.Input name="passwordConfirmation" type="password" required />
          {error && <Alert type="error" className="text-small" message={errorText} />}
          <input type="submit" style={{ display: 'none' }} />
        </form>
      )}
    </Form.Formik>
  );
}
