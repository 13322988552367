import { useCallback } from 'react';
import * as Yup from 'yup';
import i18next from 'i18next';
import { Form, notify, useAppFindFormik } from '@/components';
import { useChangePasswordMutation } from '@/redux';

type FormValue = Yup.InferType<typeof schema>;

const schema = Yup.object({
  current: Yup.string().nullable().required(),
  new: Yup.string()
    .password()
    .nullable()
    .required()
    .notOneOf([Yup.ref('current'), null], i18next.t('users.passwordChange.passwordCurrentMatchError')),
  newConfirmation: Yup.string()
    .oneOf([Yup.ref('new'), null], i18next.t('users.passwordChange.passwordConfirmationMatchError'))
    .nullable()
    .required(),
});

const useSubmit = () => {
  const [changePassword] = useChangePasswordMutation();
  const form = useAppFindFormik('password-change');

  return useCallback(
    async (value: FormValue) =>
      await changePassword(value)
        .unwrap()
        .then(() => notify.success.t('users.passwordChange.success'))
        .then(() => form.current!.resetForm()),
    [changePassword, form],
  );
};

const INITIAL_VALUE: FormValue = {
  current: '',
  new: '',
  newConfirmation: '',
};

export function ChangePasswordPanel() {
  const onSubmit = useSubmit();

  return (
    <div>
      <Form.Formik<FormValue>
        uid="password-change"
        i18nKeyPrefix="users.passwordChange"
        initialValues={INITIAL_VALUE}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <Form.Input name="current" type="password" required />
        <Form.Input name="new" type="password" required />
        <Form.Input name="newConfirmation" type="password" required />
      </Form.Formik>
    </div>
  );
}
