import { DATE_FORMAT_MOMENT, DATE_TIME_FORMAT_MOMENT } from '@/platform';
import moment from 'moment';

interface Props {
  value: Date | string;
  format?: 'date' | 'date-time';
  className?: string;
}

export const DateTimeValue = (props: Props) => {
  const { value: valueProp, className, format = 'date-time' } = props;
  const value = moment(valueProp);
  const momentFormat = format === 'date-time' ? DATE_TIME_FORMAT_MOMENT : DATE_FORMAT_MOMENT;

  return <span className={className}>{value.format(momentFormat)}</span>;
};
