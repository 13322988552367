import { useField } from 'formik';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';
import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { useCallback } from 'react';
import classnames from 'classnames';

export type FormsNumberProps = {
  name: string;
  label?: React.ReactNode | false;
  required?: boolean;
  className?: string;
  placeholder?: string;
  prefix?: React.ReactNode;
  min?: number;
  max?: number;
};

export const defaultInputNumberProps: InputNumberProps<number> = {
  formatter: (value?: number) => getThousandSeparatorExp(value),
  parser: (value?: string) => parseFormattedValue(value),
  className: 'w-100',
  precision: 2,
};

function getThousandSeparatorExp(value: number | undefined): string {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function parseFormattedValue(value: string | undefined): number {
  return +value!.replace(/\$\s?|(,*)/g, '');
}

export const FormsNumber = ({
  name,
  label: labelOverride,
  required,
  className,
  placeholder,
  prefix,
  min,
  max,
}: FormsNumberProps) => {
  const [field, , helpers] = useField(name);
  const label = useFormLabel(name, labelOverride);

  const handleChange = useCallback(
    (value: number) => {
      helpers.setValue(value);
    },
    [helpers],
  );

  return (
    <FormsGroup label={label} name={name} required={required}>
      <InputNumber
        {...field}
        {...defaultInputNumberProps}
        prefix={prefix}
        value={field.value ?? ''}
        onChange={handleChange}
        className={classnames(defaultInputNumberProps?.className, className)}
        placeholder={placeholder}
        min={min}
        max={max}
      />
    </FormsGroup>
  );
};
