import { useUserContextSelector } from '@/redux';
import { useTranslation } from 'react-i18next';
import { Role } from '@/core';

export interface AuthProps {
  role: Role;
  children: (() => React.ReactElement) | React.ReactNode;
}

function _Auth({ role, children }: AuthProps): JSX.Element | null {
  const has = useUserContextSelector((x) => x.hasRole(role));
  if (!has) {
    return null;
  }

  const content = typeof children === 'function' ? children() : children;
  return <>{content}</>;
}

function Banner() {
  const { t } = useTranslation();

  return <div className="mt-2 p-4 text-center">{t('auth.denied')}</div>;
}

function createWrap(fallbackElement: React.ReactElement | null) {
  return (role: Role | Role[]) => {
    return <T extends React.ComponentType<any>>(Component: T): T => {
      const Wrapped: React.FC = (props: any) => {
        const hasRole = useUserContextSelector((x) => x.hasRole);

        const has = Array.isArray(role) ? role.some((r) => hasRole(r)) : hasRole(role);

        return has ? <Component {...props} /> : fallbackElement;
      };
      return Wrapped as T;
    };
  };
}

export const Auth = Object.assign(_Auth, {
  Banner: createWrap(<Banner />),
});
