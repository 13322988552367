import { useCallback } from 'react';
import * as Yup from 'yup';
import { Col, Row } from 'antd';
import { array } from '@/utils';
import { formatters, Role, UpdateUserDto } from '@/core';
import { Form, notify, useAppSubmitFormik } from '@/components';
import { useGetUserQuery, useUpdateUserMutation, useUserContextSelector } from '@/redux';
import { FormUserAvatarEdit } from '@/views';

const schema = Yup.object().shape({
  firstName: Yup.string().field().nullable().required(),
  lastName: Yup.string().field().nullable().required(),
  role: Yup.string().nullable().required(),
});

export function useSubmitUserDetailsFormik() {
  return useAppSubmitFormik('user-details');
}

const useSubmit = () => {
  const [updateUser] = useUpdateUserMutation();

  return useCallback(
    async (value: UpdateUserDto) =>
      updateUser({ ...value })
        .unwrap()
        .then(() => notify.success.t('users.details.saved')),
    [updateUser],
  );
};

export function UserDetailsPanel({ id }: { id: string }) {
  const { data: user } = useGetUserQuery({ id });
  const canEdit = useUserContextSelector((x) => x.hasRole('ADMIN'));
  const handleSubmit = useSubmit();

  if (user == null) {
    return null;
  }

  return (
    <Form.Formik
      uid="user-details"
      i18nKeyPrefix="users.details"
      initialValues={{ ...user, roles: array.empty<string>() }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <Form.FieldSet disabled={!canEdit}>
        <Row>
          <Col>
            <FormUserAvatarEdit fullName={formatters.name(user)} name="avatar" />
          </Col>
          <Col offset={1} flex="auto">
            <Form.Text name="email" />
            <Form.Input name="firstName" required />
            <Form.Input name="lastName" required />
            <Form.EnumSelect type={Role} name="role" />
          </Col>
        </Row>
      </Form.FieldSet>
    </Form.Formik>
  );
}
