import { Form, notify } from '@/components';
import { Role } from '@/core';
import { useInviteUserMutation } from '@/redux';
import { useCallback } from 'react';
import * as Yup from 'yup';

const schema = Yup.object({
  email: Yup.string().email().field().nullable().required(),
  firstName: Yup.string().field().nullable().required(),
  lastName: Yup.string().field().nullable().required(),
  role: Yup.string().nullable().required(),
});

type FormValue = Yup.InferType<typeof schema>;

const useSubmit = () => {
  const [invite] = useInviteUserMutation();

  return useCallback(
    async (value: FormValue) =>
      invite(value)
        .unwrap()
        .then(() => notify.success.t('users.invited')),
    [invite],
  );
};

const INITIAL_VALUE: FormValue = {
  email: '',
  firstName: '',
  lastName: '',
  role: Role.USER,
};

export function UserAddPanel() {
  const handleSubmit = useSubmit();

  return (
    <Form.Formik<FormValue>
      uid="user-add"
      i18nKeyPrefix="users.add"
      initialValues={INITIAL_VALUE}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <Form.Input name="email" required />
      <Form.Input name="firstName" required />
      <Form.Input name="lastName" required />
      <Form.EnumSelect name="role" type={Role} required />
    </Form.Formik>
  );
}
