import {
  DATE_RANGE_FORMAT_MOMENT,
  HOUR_FORMAT_MOMENT,
  HOUR_MINUTES_FORMAT_MOMENT,
  HOUR_WITH_LEADING_ZERO_FORMAT_MOMENT,
  TIME_FORMAT_MOMENT,
} from '@/platform';
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';

export const calendarLocalizer = momentLocalizer(moment);

export const FORMATS = {
  eventTime: HOUR_MINUTES_FORMAT_MOMENT,
  timeslotTime: HOUR_WITH_LEADING_ZERO_FORMAT_MOMENT,
};

export const calendarDateRangeView = (calendarLocalizer.formats.dayRangeHeaderFormat = (range) =>
  range.start.getMonth() === range.end.getMonth()
    ? `${calendarLocalizer.format(range.start, 'DD')} - ${calendarLocalizer.format(
        range.end,
        DATE_RANGE_FORMAT_MOMENT,
      )}`
    : `${calendarLocalizer.format(range.start, 'DD MMMM')} - ${calendarLocalizer.format(
        range.end,
        DATE_RANGE_FORMAT_MOMENT,
      )}`);

function formatEventTime(value: moment.Moment) {
  return value.minutes() > 0 ? value.format(TIME_FORMAT_MOMENT) : value.format(HOUR_FORMAT_MOMENT);
}

calendarLocalizer.formats.eventTimeRangeFormat = (args) => {
  const { start, end } = args;
  return `${formatEventTime(moment(start))} - ${formatEventTime(moment(end))}`;
};

calendarLocalizer.formats.selectRangeFormat = ({ start, end }) => {
  return `${formatEventTime(moment(start))} - ${formatEventTime(moment(end))}`;
};

Object.assign(calendarLocalizer.formats, {
  eventTime: HOUR_FORMAT_MOMENT,
});
