import { useMemo } from 'react';
import { DateTimeValue, TableColumnType, YesNo } from '@/components';
import { useTranslation } from 'react-i18next';
import { NotificationListItemState } from '@webinex/wispo';
import { Button, Tag } from 'antd';

export function useNotificationsListColumns(onRead: (id: string[]) => any) {
  const { t } = useTranslation(undefined, { keyPrefix: 'notifications.list' });

  return useMemo<TableColumnType<NotificationListItemState>[]>(
    () => [
      {
        key: 'createdAt',
        title: t('createdAt'),
        dataIndex: 'createdAt',
        sortOrder: 'descend',
        showSorterTooltip: false,
        sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
        render: (_, { createdAt }) => <DateTimeValue value={createdAt} />,
      },
      {
        key: 'subject',
        title: t('subject'),
        render: (_, { subject }) => <div className="text-multiline">{subject}</div>,
      },
      {
        key: 'body',
        title: t('body'),
        render: (_, { body }) => <div className="text-multiline">{body}</div>,
      },
      {
        key: 'read',
        title: t('read'),
        render: (_, { read }) => (
          <Tag color={read ? 'success' : 'warning'}>
            <YesNo>{read}</YesNo>
          </Tag>
        ),
      },
      {
        key: 'readAt',
        title: t('readAt'),
        render: (_, { readAt }) => readAt && <DateTimeValue value={readAt} />,
      },
      {
        key: 'actions',
        title: t('actions'),
        render: (_, { markRead, read, id }) => {
          if (read || markRead.isPending || markRead.isReloading) {
            return null;
          }

          return (
            <Button onClick={() => onRead([id])} type="link">
              {t('markRead')}
            </Button>
          );
        },
      },
    ],
    [t, onRead],
  );
}
