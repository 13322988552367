import { Icon, Page } from '@/components';
import { ActivityTablePanel } from '@/views';
import { useTranslation } from 'react-i18next';

export function ActivityListPage() {
  const { t } = useTranslation();

  return (
    <Page htmlTitle={t('activity.title')}>
      <Page.Paths>
        <Page.Path>{t('activity.title')}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card icon={<Icon type="template" />} color="#0097a7" title={t('activity.title')}>
          <ActivityTablePanel />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
