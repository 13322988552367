import { useField } from 'formik';
import { useCallback } from 'react';
import { AsyncSelect, AsyncSelectProps } from '../AsyncSelect';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';

export interface FormsAsyncSelectProps extends Omit<AsyncSelectProps, 'value' | 'onChange'> {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  mode?: 'multiple';
  disabled?: boolean;
}

function useHandleChange(props: FormsAsyncSelectProps) {
  const { name } = props;
  const [, , { setValue }] = useField(name);

  return useCallback(
    (value: string | undefined) => {
      setValue(value);
    },
    [setValue],
  );
}

export function FormsAsyncSelect(props: FormsAsyncSelectProps) {
  const { name, label: labelOverride, required, disabled, ...asyncSelectProps } = props;
  const [{ value }] = useField(name);
  const label = useFormLabel(name, labelOverride);
  const handleChange = useHandleChange(props);

  return (
    <FormsGroup label={label} name={name} required={required}>
      <AsyncSelect {...asyncSelectProps} value={value} onChange={handleChange} disabled={disabled} />
    </FormsGroup>
  );
}
