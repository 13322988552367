export class PromiseSource<T> {
  private _promise: Promise<T>;
  private _resolve!: (value: T | PromiseLike<T>) => void;
  private _reject!: (reason?: any) => void;

  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public get promise() {
    return this._promise;
  }

  public resolve = (value: T | PromiseLike<T>) => {
    this._resolve(value);
  };

  public reject = (reason?: any) => {
    this._reject(reason);
  };
}
