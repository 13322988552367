import { LookupSource, LookupOption, createEntireLoadLookupSource } from '@/components';
import { RtkqSpin, useLazyGetUserLookupsQuery, UserLookupState } from '@/redux';
import { Typography } from 'antd';
import { useMemo } from 'react';

export function mapUserLookup(value: UserLookupState): LookupOption {
  return {
    label: (
      <div>
        <div>
          {value.firstName} {value.lastName}
        </div>
        <div>
          <Typography.Text type="secondary">{value.email}</Typography.Text>
        </div>
      </div>
    ),
    value: value.id,
    _searchValue: `${value.firstName} ${value.lastName} ${value.email}`,
  };
}

export function useUserLookupSource(): LookupSource {
  const [fetchList] = useLazyGetUserLookupsQuery();

  return useMemo(() => {
    return createEntireLoadLookupSource({
      load: () =>
        fetchList({ [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) => values.map(mapUserLookup)),
      searchField: '_searchValue',
    });
  }, [fetchList]);
}
