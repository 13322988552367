import classes from './NotificationsAlert.module.scss';
import { useAppUnreadCount } from '@/core';
import { Popover } from 'antd';
import { Icon } from '@/components';
import { NotificationAlertOverlay } from './NotificationAlertOverlay';

function OverlayFactory() {
  return <NotificationAlertOverlay />;
}

export function NotificationsAlert() {
  const unread = useAppUnreadCount();

  return (
    <Popover content={OverlayFactory} placement="bottomRight" destroyTooltipOnHide>
      <a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <div className={classes.container}>
          <Icon type="bell" />
          <div className={classes.count}>{unread !== undefined ? unread : '?'}</div>
        </div>
      </a>
    </Popover>
  );
}
