import { useTranslation } from 'react-i18next';

import { UsersListPanel } from '@/views';
import { Auth, Icon, Page } from '@/components';
import { NavLink } from 'react-router-dom';

function _UsersListPage() {
  const { t } = useTranslation();
  const title = t('users.title');

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          title={title}
          icon={<Icon type="users" />}
          color="#d84315"
          actions={
            <NavLink className="ant-btn ant-btn-primary" to="/users/invite">
              <>
                <Icon type="add" /> {t('users.add.btn')}
              </>
            </NavLink>
          }
        >
          <UsersListPanel />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}

export const UsersListPage = Auth.Banner('ADMIN')(_UsersListPage);
