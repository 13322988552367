import { LookupSource, LookupOption, createEntireLoadLookupSource } from '@/components';
import { useAppActivityKinds } from '@/core';
import { PromiseSource } from '@/utils';
import { getActivityKindKey } from '@/views';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export function useActivityKindLookupSource(): LookupSource {
  const { t } = useTranslation();
  const source = useRef(new PromiseSource<LookupOption[]>());

  const {
    state: { values: kinds },
    fetch,
  } = useAppActivityKinds();

  useEffect(() => {
    if (isEmpty(kinds)) {
      return;
    }

    source.current.resolve(
      kinds.map((kind) => ({
        value: kind,
        label: t('activity.kind.' + getActivityKindKey(kind), { defaultValue: kind }),
      })),
    );
  }, [kinds, t]);

  return useMemo(() => {
    return createEntireLoadLookupSource(() => {
      fetch();
      return source.current.promise;
    });
  }, [fetch]);
}
