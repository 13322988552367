const localStorageKey = 'Features-Overrides';
const headerKey = 'X-Features-Overrides';

export type FeatureFlag = keyof AppSettings['features'];

const isFeatureEnabled = (name: FeatureFlag) => {
  return window.appSettings.features[name] === true || getLocalFeatureSettings()[name] === true;
};

export const getFeatureFlagsOverridesHeader = () => {
  const featureSettingsStored = getLocalFeatureSettings();

  return {
    key: headerKey,
    value: Object.entries(featureSettingsStored)
      .filter(([, value]) => !!value)
      .map(([key]) => key)
      .join(';'),
  };
};

const getLocalFeatureSettings = (): Partial<Record<FeatureFlag, boolean>> => {
  const featureSettingsStored = localStorage.getItem(localStorageKey);
  return featureSettingsStored ? JSON.parse(featureSettingsStored) : {};
};

const updateLocalFeatureSettings = (name: FeatureFlag, enabled: boolean) => {
  const featureSettings = getLocalFeatureSettings();
  featureSettings[name] = enabled;
  localStorage.setItem(localStorageKey, JSON.stringify(featureSettings));
  reloadPage();
};

const clearLocalFeatureSettings = () => {
  localStorage.removeItem(localStorageKey);
  reloadPage();
};

const reloadPage = () => {
  // eslint-disable-next-line
  window.location.href = window.location.href;
};

export const featureFlags = { isEnabled: isFeatureEnabled };

(window as any).featureFlags = {
  ...featureFlags,
  clearAllLocal: clearLocalFeatureSettings,
  showLocal: getLocalFeatureSettings,
  updateLocal: updateLocalFeatureSettings,
};
