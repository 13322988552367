import axios, { AxiosInstance } from 'axios';
import { getFeatureFlagsOverridesHeader } from '../featureFlags';

class SettingsHttp {
  protected _axios: AxiosInstance;

  constructor() {
    this._axios = axios.create();

    this._axios.interceptors.request.use(async (request) => {
      const { key, value } = getFeatureFlagsOverridesHeader();
      request.headers![key] = value;
      return request;
    });
  }

  public async get() {
    const response = await this._axios.get<AppSettings>('/api/settings');
    return response.data;
  }
}

export const settingsHttp = new SettingsHttp();
