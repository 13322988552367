import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { UserAvatarEdit, UserAvatarEditProps } from './UserAvatarEdit';

type UserAvatarEditFormikProps = Omit<UserAvatarEditProps, 'onChange' | 'reference'> & {
  name: string;
};

function useHandleChange(props: UserAvatarEditFormikProps) {
  const { name } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return useCallback(
    (reference: string) => {
      setFieldValue(name, reference);
      setFieldTouched(name);
    },
    [setFieldValue, setFieldTouched, name],
  );
}

function useValue(props: UserAvatarEditFormikProps) {
  const { name } = props;
  const { values } = useFormikContext<any>();
  return values[name];
}

export function FormUserAvatarEdit(props: UserAvatarEditFormikProps) {
  const { name, ...otherProps } = props;
  const value = useValue(props);
  const handleChange = useHandleChange(props);

  return <UserAvatarEdit {...otherProps} onChange={handleChange} reference={value} />;
}
