import { useEffect, useMemo } from 'react';
import { spinner } from '@/components';
import { useAppActivityList } from '@/core';
import { Activity } from '@webinex/activity';
import { FilterRule, SortRule } from '@webinex/asky';
import { ActivityOperationItem } from './ActivityOperationItem';

export interface ActivityOperationPanelProps {
  operationId: string;
}

const SORT_RULE: SortRule = {
  fieldId: 'performedAt',
  dir: 'asc',
};

function useViewState({ operationId }: ActivityOperationPanelProps) {
  const { state, fetch } = useAppActivityList();

  useEffect(() => {
    if (!operationId) return;

    const filter: FilterRule = {
      operator: '=',
      fieldId: 'operationId',
      value: operationId,
    };

    spinner.show();
    fetch({ filter, sort: SORT_RULE }).finally(() => spinner.hide());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationId]);

  return { state };
}

export function ActivityOperationList(props: ActivityOperationPanelProps) {
  const { state } = useViewState(props);
  const { itemById } = state;
  const values = useMemo(() => Object.values(itemById), [itemById]);
  const top = useMemo(() => values.filter((x: Activity) => !x.parentId), [values]);

  return (
    <div>
      {top.map((item) => (
        <ActivityOperationItem key={item.id} id={item.id} values={values} />
      ))}
    </div>
  );
}
