import { Button } from 'antd';
import { AttachProps } from './attachProps';
import { FileOutlined } from '@ant-design/icons';
import styles from './Attach.module.scss';
import { Delete } from '../Delete';
import { Icon } from '../Icon';

export function AttachList(props: AttachProps) {
  const { remove, readonly, open, value, noDeleteConfirm } = props;
  const { items, fetchPending } = value;

  return (
    <ul className={styles.list}>
      {items.map((item) => (
        <li key={item.id} title={item.fileName}>
          <span className={styles.title}>
            <Button
              icon={<FileOutlined />}
              disabled={readonly || fetchPending}
              type="link"
              onClick={() => open(item.id)}
            >
              <span className={styles.fileName}>{item.fileName}</span>
            </Button>
          </span>
          <Delete
            nameKey="attach.entityName"
            onConfirm={() => remove({ id: item.id })}
            noConfirm={noDeleteConfirm}
          >
            <Button
              className={styles.delete}
              disabled={readonly || fetchPending}
              type="link"
              icon={<Icon type="delete" />}
            />
          </Delete>
        </li>
      ))}
    </ul>
  );
}
