import { Tag } from 'antd';
import { UserState } from '@/redux';
import { useTranslation } from 'react-i18next';

interface Props {
  user: Pick<UserState, 'isActive' | 'passwordLockedOut' | 'creating'>;
}

export function UserStatusBadge({ user }: Props) {
  const { t } = useTranslation();

  const { isActive, passwordLockedOut, creating } = user;

  const warningStatus =
    (isActive === false && 'deactivated') || (passwordLockedOut === true && 'locked') || undefined;

  return (
    <>
      {warningStatus && <Tag color="orange">{t(`users.status.${warningStatus}`)}</Tag>}
      {creating && <Tag color="blue">{t(`users.status.creating`)}</Tag>}
      {!warningStatus && !creating && <Tag color="green">{t(`users.status.active`)}</Tag>}
    </>
  );
}
