import { enumObject, EnumValue } from '@/utils';

export interface ChangePasswordDto {
  current: string;
  new: string;
}

export interface SignUpDto {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface UpdateProfileDto {
  firstName: string;
  lastName: string;
  avatar?: string | null;
}

export interface UpdateUserDto {
  id: string;
  firstName: string;
  lastName: string;
  roles: string[];
}

export interface UserContextDto {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
  avatar?: string;
}

export interface UserDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
  emailConfirmed?: boolean;
  passwordLockedOut?: boolean;
  isActive?: boolean;
  creating: boolean;
  avatar?: string;
}

export interface UserLookupDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface UserInviteDto {
  email: string;
  firstName: string;
  lastName: string;
}

const ROLE_VALUES = ['ADMIN', 'USER'] as const;
export type Role = EnumValue<typeof ROLE_VALUES>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = enumObject('Role', ROLE_VALUES);
