import * as yup from 'yup';
import { StringSchema } from 'yup';

function password(this: StringSchema) {
  const lowercase = { message: { key: 'string.matches.password.lowercase' } };
  const uppercase = { message: { key: 'string.matches.password.uppercase' } };
  const digits = { message: { key: 'string.matches.password.digits' } };

  return this.max(250)
    .min(8)
    .matches(/[a-z]/, lowercase)
    .matches(/[A-Z]/, uppercase)
    .matches(/[0-9]/, digits);
}

yup.addMethod(yup.string, 'password', password);
