import './i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { store, useGetContextQuery } from '@/redux';
import { openid, CodedException, settingsHttp, wispoClient } from '@/core';
import { Layout } from '@/views';

import { SignUpPage, Pages } from '@/pages';
import { ConfirmationGlobal, notify, Spinner } from './components';
import { RtkqCatchedError } from './redux/rtkq';

const render = (Root: React.ComponentType) => {
  // Antd not ready to be switched to react-dom/client

  ReactDOM.render(
    <React.StrictMode>
      <HelmetProvider>
        <Spinner />
        <ConfirmationGlobal />
        <BrowserRouter>
          <Provider store={store}>
            <Root />
          </Provider>
        </BrowserRouter>
      </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

function App() {
  const { isLoading } = useGetContextQuery();

  if (isLoading) {
    return null;
  }

  return (
    <Layout>
      <Pages />
    </Layout>
  );
}

const run = async () => {
  window.appSettings = await settingsHttp.get();

  if (window.location.pathname.match(/^\/sign-up\/?$/)) {
    render(SignUpPage);
    return;
  }

  if (await openid.ensureAuthenticated()) {
    wispoClient.connect();
    render(App);
  }
};

run();

window.addEventListener('unhandledrejection', (event) => {
  const error = event.reason;
  if (error instanceof RtkqCatchedError) {
    return;
  }

  const codedException = CodedException.from(error);
  notify.error.coded(codedException);
});
