import { FC } from 'react';
import { Avatar, Card, Space, Typography } from 'antd';
import styles from './Page.module.scss';
import classNames from 'classnames';

export interface PageCardProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  actions?: React.ReactNode;
  actionsPosition?: 'top' | 'bottom';
  icon?: React.ReactNode;
  color?: string;
  subTitle?: React.ReactNode;
}

const PageCard: FC<PageCardProps> = (props) => {
  const { actions, icon, title: titleProp, subTitle, color, actionsPosition, ...cardProps } = props;

  const noBodyClassName = !cardProps.children;

  const title =
    actions || icon || titleProp ? (
      <Space size="middle">
        {icon && (
          <Avatar
            size={36}
            icon={icon}
            style={{
              backgroundColor: color,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        )}
        <Space align="baseline" size="middle">
          {titleProp && (
            <Typography.Title level={2} className="mb-0">
              {titleProp}
            </Typography.Title>
          )}
          {subTitle && (
            <Typography.Title level={3} type="secondary" className="mb-0">
              {subTitle}
            </Typography.Title>
          )}
        </Space>
      </Space>
    ) : undefined;

  const extra = actionsPosition === 'top' ? actions : <></>;

  return (
    <Card
      bordered={false}
      {...cardProps}
      extra={extra}
      actions={actionsPosition === 'bottom' ? [actions] : undefined}
      title={title}
      className={classNames(props.className, styles.card, noBodyClassName, 'ant-card-shadowed')}
    />
  );
};

PageCard.defaultProps = {
  actionsPosition: 'top',
};

export const PageCardExports = {
  PageCard,
};
