import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { Col, Menu, Row, Space } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import { featureFlags, openid } from '@/core';
import { useUserContextSelector } from '@/redux';
import { Avatar, confirmation } from '@/components';
import { NotificationsAlert } from '@/views';
import css from './Layout.module.scss';
import { useSelectedNavMenuKeys } from './useSelectedNavMenuKeys';

function useNavMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name, avatar] = useUserContextSelector((x) => [x.name, x.avatar]);
  const hasRole = useUserContextSelector((x) => x.hasRole);

  const showSignOutConfirmationDialog = () => {
    confirmation.show({
      title: t('logoutConfirmation.title'),
      body: t('logoutConfirmation.body'),
      onConfirm: () => openid.logout(),
    });
  };

  const items: ItemType[] = [
    {
      key: 'docs',
      label: <a href="https://webinex.github.io/starter-kit/">{t('nav.docs')}</a>,
    },
    {
      key: '/dashboard',
      label: <NavLink to="/dashboard">{t('nav.dashboard')}</NavLink>,
    },
    {
      key: '/demo',
      label: <NavLink to="/demo">Demo</NavLink>,
    },
    hasRole('ADMIN') &&
      featureFlags.isEnabled('ActivityPage') && {
        key: '/activity',
        label: <NavLink to="/activity">{t('activity.title')}</NavLink>,
      },
    hasRole('ADMIN') && {
      key: '/users',
      label: <NavLink to="/users">{t('users.title')}</NavLink>,
    },
    {
      key: '/calendar',
      label: <NavLink to="/calendar">{t('calendars.title')}</NavLink>,
    },
    {
      key: '/notifications',
      label: <NotificationsAlert />,
    },
    {
      key: '/user-actions',
      icon: (
        <Space align="center">
          <Avatar size={32} reference={avatar} alt={name} />
        </Space>
      ),
      className: 'user-actions',
      children: [
        {
          key: '/me',
          label: <NavLink to="/me">{name}</NavLink>,
        },
        {
          key: '/change-password',
          onClick: () => navigate('/change-password'),
          label: t('nav.changePassword'),
        },
        {
          key: '/logout',
          onClick: showSignOutConfirmationDialog,
          label: t('nav.logout'),
        },
      ].filter((x) => !!x) as ItemType[],
    },
  ].filter((x) => !!x) as ItemType[];

  const selectedKeys = useSelectedNavMenuKeys(items);

  return (
    <Menu selectedKeys={selectedKeys} className="nav" mode="horizontal" disabledOverflow items={items} />
  );
}

export function NavPanel() {
  const navMenu = useNavMenu();
  const { t } = useTranslation();

  return (
    <Row justify="space-between" gutter={[0, 0]}>
      <Col>
        <NavLink to="/" className={classNames(css.brand, 'ms-2')}>
          {t('brandFullName')}
        </NavLink>
      </Col>
      <Col>{navMenu}</Col>
    </Row>
  );
}
