import styles from '../Calendar.module.scss';
import { FORMATS, calendarLocalizer } from '../calendarLocalizer';

const AM_PM_VALUES = ['am', 'pm'];

export function CustomTimeSlotWrapper(props: { resource: any; value: Date; children: React.ReactNode }) {
  const { resource, value, children } = props;

  if (resource === null || value.getMinutes() !== 0) {
    return children;
  }

  const splitted = calendarLocalizer.format(value, FORMATS.timeslotTime).split(' ');
  const values = AM_PM_VALUES.includes(splitted.at(-1)!.toLowerCase())
    ? { time: splitted.slice(0, splitted.length - 1).join(' '), amPm: splitted.at(-1) }
    : { time: splitted.join(' '), amPm: undefined };

  return (
    <div className={styles.timeslot}>
      <span className={styles.time}>{values.time}</span>
      {values.amPm && <span className={styles.amPm}>{values.amPm}</span>}
    </div>
  );
}
