import React, { useCallback, useEffect, useRef } from 'react';
import { FormikProps, useFormikContext } from 'formik';

const VALUE: Record<string, React.MutableRefObject<FormikProps<any> | undefined>> = {};

export function useAppFindFormik<T = any>(uid: string): React.MutableRefObject<FormikProps<T> | undefined> {
  const ref = useRef<FormikProps<T>>();
  return VALUE[uid] ?? (VALUE[uid] = ref);
}

export function useAppSubmitFormik(uid: string) {
  const form = useAppFindFormik(uid);
  return useCallback(() => form.current!.submitForm(), [form]);
}

export function useFormikFormRegistration(uid: string) {
  const formik = useFormikContext();
  const ref = useRef(formik);
  VALUE[uid] = VALUE[uid] ?? ref;
  VALUE[uid].current = formik;

  useEffect(() => {
    return () => {
      VALUE[uid].current = undefined;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
