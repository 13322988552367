import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

export interface UseFormErrorMessageArgs {
  name: string;
  label?: React.ReactNode;
  mode?: 'touched' | 'always';
}

export type FormsErrorMessageProps = UseFormErrorMessageArgs & {
  children?: (error: string) => JSX.Element;
};

export function useFormErrorMessage({ name, label, mode = 'touched' }: UseFormErrorMessageArgs) {
  const [, meta] = useField(name);
  const { t } = useTranslation();
  let error: any = meta.error;

  if (typeof error !== 'string' && error?.key !== undefined) {
    error = t(`errors.${error.key}`, { ...error, label });
  }

  const show = meta.error && (mode !== 'touched' || meta.touched);

  return {
    error,
    show,
  };
}

export function FormsErrorMessage(props: FormsErrorMessageProps) {
  const { children } = props;
  const { error, show } = useFormErrorMessage(props);

  if (!show) {
    return null;
  }

  if (children) {
    return children(error);
  }

  return <span>{error}</span>;
}
