import { Form, notify } from '@/components';
import { formatters } from '@/core';
import { FormUserAvatarEdit } from '@/views';
import { useGetProfileQuery, useUpdateProfileMutation } from '@/redux';
import { Col, Row } from 'antd';
import { useCallback } from 'react';
import * as Yup from 'yup';

const schema = Yup.object({
  email: Yup.string().required(),
  firstName: Yup.string().field().nullable().required(),
  lastName: Yup.string().field().nullable().required(),
  avatar: Yup.string().nullable(),
});

type FormValue = Yup.InferType<typeof schema>;

const useSubmit = () => {
  const { data } = useGetProfileQuery();
  const [update] = useUpdateProfileMutation();
  const { id } = data ?? {};

  return useCallback(
    async (values: FormValue) =>
      await update({ ...values, id: id! })
        .unwrap()
        .then(() => notify.success.t('users.profile.saved')),
    [update, id],
  );
};

export function UserProfilePanel() {
  const { data } = useGetProfileQuery();
  const onSubmit = useSubmit();

  if (!data) {
    return null;
  }

  const { firstName, lastName, avatar, email } = data;

  return (
    <div>
      <Form.Formik<FormValue>
        uid="user-profile"
        i18nKeyPrefix="users.profile"
        initialValues={{ firstName, lastName, avatar, email }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <Row>
          <Col>
            <FormUserAvatarEdit fullName={formatters.name(data)} name="avatar" />
          </Col>
          <Col offset={1} flex="auto">
            <Form.Text name="email" />
            <Form.Input name="firstName" required />
            <Form.Input name="lastName" required />
          </Col>
        </Row>
      </Form.Formik>
    </div>
  );
}
