import { DateTimeValue, Icon, TextBox } from '@/components';
import { formatters } from '@/core';
import { CalendarEvent } from '@/redux';
import { useBindArgs, useBool } from '@/utils';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReassignCalendarEventForm } from './ReassignCalendarEventForm';

export interface CalendarEventDetailsModalProps {
  event: CalendarEvent;
  onCancel: () => any;
  onCancelAppearance: (event: CalendarEvent) => any;
  onCancelLine: (event: CalendarEvent) => any;
  onReassign: (event: CalendarEvent, assignees: string[], thisAndBeyond: boolean) => any;
}

export function CalendarEventDetailsModal(props: CalendarEventDetailsModalProps) {
  const { event, onCancel, onCancelAppearance, onCancelLine, onReassign } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'calendars.details' });
  const [showCancel, , toggleShowCancel] = useBool(false);
  const [showReassign, , toggleReassign] = useBool(false);
  const cancelAppearance = useBindArgs(onCancelAppearance, event);
  const cancelLine = useBindArgs(onCancelLine, event);

  return (
    <>
      <Modal
        visible
        title={event.name}
        onCancel={onCancel}
        footer={[
          <Button type="primary" danger key="cancel-event" onClick={toggleShowCancel}>
            {t('cancelEvent')}
          </Button>,
          <Button key="cancel" onClick={onCancel}>
            {t('ok')}
          </Button>,
        ]}
      >
        <TextBox label={t('name')}>{event.name}</TextBox>
        <TextBox label={t('period')}>
          <DateTimeValue value={event.start} /> - <DateTimeValue value={event.end} />
        </TextBox>
        {!showReassign && (
          <TextBox label={t('assignees')}>
            {event.assignees.map(formatters.name).join(', ') ?? 'None'}
            <Button onClick={toggleReassign} type="link" className="ms-3" icon={<Icon type="edit" />} />
          </TextBox>
        )}
        {showReassign && (
          <ReassignCalendarEventForm event={event} onCancel={toggleReassign} onSubmit={onReassign} />
        )}
      </Modal>

      {showCancel && (
        <Modal
          visible
          title={t('cancel.title')}
          onCancel={toggleShowCancel}
          footer={
            <>
              <Button type="primary" danger onClick={cancelAppearance}>
                {t('cancel.cancelAppearanceButton')}
              </Button>
              {event.recurrentEventId && (
                <Button type="primary" danger onClick={cancelLine}>
                  {t('cancel.cancelLineButton')}
                </Button>
              )}
              <Button onClick={toggleShowCancel}>{t('cancel.close')}</Button>
            </>
          }
        >
          {t('cancel.body')}
        </Modal>
      )}
    </>
  );
}
