import { Form, Page } from '@/components';
import { useBool } from '@/utils';
import { SignUpPanel } from '@/views';
import { Alert, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

export function SignUpPage() {
  const { t } = useTranslation(undefined, { keyPrefix: 'signup' });
  const [submitted, , toggleSubmitted] = useBool(false);

  return (
    <Page>
      <Page.Body span={{ span: 12, offset: 6 }}>
        <Page.Card
          title={t('title')}
          actionsPosition="bottom"
          actions={
            <>
              <div className="text-center">
                {!submitted && (
                  <Form.Submit className="w-100" uid="signup" type="primary">
                    {t('submit')}
                  </Form.Submit>
                )}
                <div className="mt-5">
                  <a href="/">{t('signin')}</a>
                </div>
                <Typography.Paragraph type="secondary" className="mb-0 text-center mt-2 text-small">
                  <Trans i18nKey="copyright" values={{ year: new Date().getFullYear() }} />
                </Typography.Paragraph>
              </div>
            </>
          }
        >
          {!submitted && <SignUpPanel onSubmitted={toggleSubmitted} />}
          {submitted && <Alert className="text-multiline" type="success" message={t('success')} />}
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
