import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmation, ConfirmationArgs } from '@/components';
import { Button, Modal } from 'antd';

function useConfirmationState() {
  const [args, setArgs] = useState<ConfirmationArgs>();

  const onCancel = useCallback(() => {
    args!.onCancel && args!.onCancel();
    setArgs(undefined);
  }, [setArgs, args]);

  const onConfirm = useCallback(() => {
    args!.onConfirm && args!.onConfirm();
    setArgs(undefined);
  }, [setArgs, args]);

  useEffect(() => {
    confirmation._subscribe(setArgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    show: !!args,
    onCancel,
    onConfirm,
    title: args?.title,
    body: args?.body,
    confirm: args?.confirm,
    cancel: args?.cancel,
  };
}

export const ConfirmationGlobal: FC = () => {
  const { t } = useTranslation();
  const { show, onCancel, onConfirm, title, body, confirm, cancel } = useConfirmationState();

  if (!show) {
    return <></>;
  }

  return (
    <Modal
      visible
      title={title ?? t('confirmation.title')}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel} key="cancel">
          {cancel ?? t('confirmation.cancel')}
        </Button>,
        <Button type="primary" autoFocus onClick={onConfirm} key="confirm">
          {confirm ?? t('confirmation.confirm')}
        </Button>,
      ]}
    >
      {body ?? t('confirmation.body')}
    </Modal>
  );
};
