import moment, { Moment } from 'moment';
import { stringOrDate, Event } from 'react-big-calendar';

export type DateUnit = string | Date | Moment;

export interface DateUnitRange {
  start: DateUnit;
  end: DateUnit;
}

export interface DropOrResizeInfo<TEvent extends Event = Event> {
  event: TEvent;
  start: stringOrDate;
  end: stringOrDate;
  isAllDay: boolean;
}

/**
 * react-big-calendar uses inclusive date approach.
 * It means if event end at 18:00 at 18:00 event still exists.
 * Our application uses exclusive date approach
 * and it means if event ends in 18:00, since 18:00 this event doesn't exist.
 *
 * As well, react-big-calendar day ends at 23:59:59.000
 * @param end inclusive end
 * @returns exclusive end of same type
 */
function convertInclusiveEndToExclusive(end: DateUnit): Date {
  return moment(end).seconds() > 0
    ? moment(end).startOf('minute').add(1, 'minute').toDate()
    : moment(end).toDate();
}

export const DndCalendarUtils = { convertInclusiveEndToExclusive };
