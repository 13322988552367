import { MONTH_DAY_FORMAT_MOMENT, WEEK_DAY_FORMAT_MOMENT } from '@/platform';
import classNames from 'classnames';
import moment from 'moment';
import { HeaderProps } from 'react-big-calendar';
import styles from '../Calendar.module.scss';

export function CustomDateHeader(props: HeaderProps) {
  const { date, localizer } = props;
  const isToday = moment().startOf('day').utc().isSame(moment(date));

  return (
    <div className={classNames(styles.timeHeaderCell, { '--today': isToday })}>
      <div className={styles.weekday}>{localizer.format(date, WEEK_DAY_FORMAT_MOMENT)}</div>
      <div className={styles.date}>{localizer.format(date, MONTH_DAY_FORMAT_MOMENT)}</div>
    </div>
  );
}
