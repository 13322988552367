import { FieldRow, Form, Icon } from '@/components';
import { ActivityValueFilter } from '@webinex/activity';
import { Button, Col } from 'antd';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export const ACITIVITY_FILTER_INITIAL_VALUE: ActivityValueFilter = { path: '', value: null };

interface Props {
  name: string;
}

function useAdd(props: Props) {
  const { name } = props;
  const [{ value }, , { setValue }] = useField<ActivityValueFilter[]>(name);
  return useCallback(() => setValue([...value, { ...ACITIVITY_FILTER_INITIAL_VALUE }]), [value, setValue]);
}

function useRemove(args: { index: number } & Props) {
  const { name, index } = args;
  const [{ value }, , { setValue }] = useField<ActivityValueFilter[]>(name);
  return useCallback(
    () => setValue(value.slice(0, index).concat(value.slice(index + 1))),
    [value, setValue, index],
  );
}

function Field(props: { index: number } & Props) {
  const { name, index } = props;
  const onRemove = useRemove(props);

  return (
    <FieldRow wrap={false}>
      <Col flex={8}>
        <Form.Input name={`${name}[${index}].path`} required />
      </Col>
      <Col flex={14}>
        <Form.Input name={`${name}[${index}].value`} />
      </Col>
      <Col flex="auto">
        <Button type="link" onClick={onRemove} className="mt-4 pt-4" icon={<Icon type="delete" />} />
      </Col>
    </FieldRow>
  );
}

export function FormActivityValueFilters(props: Props) {
  const { name } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'activity.table.valueFilters' });
  const [{ value }] = useField<ActivityValueFilter[]>(name);
  const onAdd = useAdd(props);

  return (
    <div>
      {value.map((_, index) => (
        <Field {...props} index={index} />
      ))}

      <Button type="link" onClick={onAdd} icon={<Icon type="add" />}>
        {t('add')}
      </Button>
    </div>
  );
}
