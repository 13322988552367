import { Select } from 'antd';
import { isEmpty } from 'lodash';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { CSSProperties, FC, useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { LookupOption, LookupSource } from '../lookups';
import classNames from 'classnames';

export interface AsyncSelectBaseProps {
  searchValue: string;
  onSearchChange: (value: string) => any;

  value: string | undefined;
  onChange: (value: string | undefined, option: any | any[]) => any;
  dataSource: LookupSource;
  options?: (options: LookupOption[]) => LookupOption[];
  initial?: LookupOption | LookupOption[];
  placeholder?: string;
  className?: string;
  preload?: boolean;
  onSearchLoad?: (options: LookupOption[]) => any;
  style?: CSSProperties;
  disabled?: boolean;
  allowClear?: boolean;
  mode?: 'multiple' | 'tags' | undefined;
}

interface State {
  isFetching: boolean;
  options: LookupOption[];
  initialOption?: LookupOption;
}

function reducer(state: State, action: any): State {
  switch (action.type) {
    case 'fetch/pending':
      return { ...state, isFetching: true };
    case 'fetch/completed':
      return {
        ...state,
        isFetching: false,
        options: action.payload?.options ?? [],
      };
    case 'initial/pending':
      return { ...state, isFetching: true };
    case 'initial/completed':
      return {
        ...state,
        isFetching: false,
        initialOption: action.payload?.value,
      };
    default:
      throw new Error();
  }
}

function useThisReducer() {
  const state: State = { isFetching: false, options: [] };
  return useReducer(reducer, state);
}

function useFetch(props: AsyncSelectBaseProps, [, dispatch]: ReturnType<typeof useThisReducer>) {
  const { dataSource, onSearchLoad } = props;
  const { search } = dataSource;

  return useCallback(
    (searchString: string | undefined) => {
      dispatch({ type: 'fetch/pending' });
      search(searchString)
        .then((options) => {
          dispatch({ type: 'fetch/completed', payload: { options } });
          onSearchLoad && onSearchLoad(options);
        })
        .catch(() => dispatch({ type: 'fetch/completed' }));
    },
    [dispatch, onSearchLoad, search],
  );
}

function usePreloadInitial(props: AsyncSelectBaseProps, [, dispatch]: ReturnType<typeof useThisReducer>) {
  const { initial } = props;

  async function preload() {
    if (!initial) {
      return;
    }

    dispatch({
      type: 'initial/completed',
      payload: { value: initial },
    });
    return;
  }

  useEffect(() => {
    preload();

    // eslint-disable-next-line
  }, [initial]);
}

function useSearch(props: AsyncSelectBaseProps, reducer: ReturnType<typeof useThisReducer>) {
  const { searchValue } = props;
  const fetch = useFetch(props, reducer);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = true;
      return;
    }

    const normalized = isEmpty(searchValue) ? undefined : searchValue;
    fetch(normalized);
  }, [fetch, searchValue]);
}

function usePreload(props: AsyncSelectBaseProps, reducer: ReturnType<typeof useThisReducer>) {
  const { preload } = props;
  const fetch = useFetch(props, reducer);

  useEffect(() => {
    if (preload) {
      fetch(undefined);
    }

    // eslint-disable-next-line
  }, []);
}

function useOptions(reducer: ReturnType<typeof useThisReducer>) {
  const [{ options, initialOption }] = reducer;

  const resultOptions = useMemo(() => {
    const result = [...(options ?? [])];
    if (initialOption && !result.some((x) => x.value !== initialOption.value)) {
      result.push(initialOption);
    }

    return result;
  }, [options, initialOption]);

  return {
    options: resultOptions,
    initialOption,
  };
}

function useHandleOpen(props: AsyncSelectBaseProps, reducer: ReturnType<typeof useThisReducer>) {
  const { preload } = props;
  const executed = useRef(false);
  const fetch = useFetch(props, reducer);

  return useCallback(() => {
    if (executed.current || preload) {
      return;
    }

    executed.current = true;
    fetch(undefined);
  }, [fetch, executed, preload]);
}

export const AsyncSelectBase: FC<AsyncSelectBaseProps> = (props) => {
  const {
    placeholder,
    className,
    dataSource,
    value,
    onChange,
    style,
    disabled,
    searchValue,
    onSearchChange,
    options: OptionsFn,
    allowClear,
    mode,
  } = props;

  const reducer = useThisReducer();
  useSearch(props, reducer);
  usePreload(props, reducer);
  const [{ isFetching }] = reducer;
  usePreloadInitial(props, reducer);
  const { options } = useOptions(reducer);
  const handleOpen = useHandleOpen(props, reducer);

  const mappedOptions = useMemo(() => (OptionsFn ? OptionsFn(options) : options), [options, OptionsFn]);

  return (
    <Select
      mode={mode}
      showSearch
      value={value}
      placeholder={placeholder}
      style={style}
      filterOption={true}
      onChange={onChange}
      allowClear={allowClear ?? true}
      searchValue={searchValue ?? ''}
      onSearch={onSearchChange}
      notFoundContent={null}
      options={mappedOptions}
      optionFilterProp={dataSource.searchField ?? 'label'}
      className={classNames(className, 'w-100')}
      suffixIcon={isFetching ? <LoadingOutlined spin /> : <SearchOutlined />}
      onDropdownVisibleChange={handleOpen}
      disabled={disabled}
    />
  );
};
