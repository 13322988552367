import { Modal } from 'antd';
import { CreateCalendarEventForm, CreateCalendarEventFormProps } from './CreateCalendarEventForm';
import { useTranslation } from 'react-i18next';
import { useAppSubmitFormik } from '@/components';

export type CreateCalendarEventModalProps = Pick<
  CreateCalendarEventFormProps,
  'initialValues' | 'onSubmit'
> & {
  onCancel: () => any;
};

export function CreateCalendarEventModal(props: CreateCalendarEventModalProps) {
  const { initialValues, onSubmit, onCancel } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'calendars.create' });
  const submitForm = useAppSubmitFormik('create-calendar-event');

  return (
    <Modal visible title={t('title')} onOk={submitForm} okType="primary" onCancel={onCancel}>
      <CreateCalendarEventForm initialValues={initialValues} onSubmit={onSubmit} />
    </Modal>
  );
}
