import moment from 'moment';

const REGEX = /^(\d?\d):(\d\d)\s(am|AM|pm|PM)$/;

const MINUTES_IN_DAY = 24 * 60;

export interface Time {
  hour: number;
  minute: number;
}

export function isEqualTime(x: Time | null, y: Time | null) {
  return x?.hour === y?.hour && x?.minute === y?.minute;
}

export function parseTimeText(text: string | null | undefined): Time | null {
  const match = REGEX.exec(text ?? '');

  if (match == null) {
    return null;
  }

  let hour = Number.parseInt(match[1]);
  if (hour > 12 || hour <= 0) return null;

  const minute = Number.parseInt(match[2]);
  if (minute > 59) {
    return null;
  }

  const amPm = match[3].toUpperCase() as 'AM' | 'PM';

  if (amPm === 'PM' && hour < 12) hour = hour + 12;
  if (amPm === 'AM' && hour === 12) hour = hour - 12;

  return { hour, minute };
}

export function addTimeOffset(time: Time, offsetMinutes: number): Time {
  let totalMinutes = convertTimeToMinutes(time)! + offsetMinutes;
  totalMinutes = totalMinutes < 0 ? MINUTES_IN_DAY + totalMinutes : totalMinutes;
  totalMinutes = totalMinutes % MINUTES_IN_DAY;

  const hour = Math.floor(totalMinutes / 60);
  const minute = totalMinutes - hour * 60;
  return { hour, minute };
}

export function convertMinutesToTime(value: number | null): Time | null {
  if (value == null) {
    return null;
  }

  const hour = Math.floor(value / 60);
  const minute = value - hour * 60;
  return { hour, minute };
}

export function convertTimeToTimeInput(time: Time | null): string | null {
  if (time == null) return null;
  return moment().set('hour', time.hour).set('minute', time.minute).format('hh:mm A');
}

export function convertTimeToMinutes(time: Time | null): number | null {
  if (time == null) return null;
  return time.hour * 60 + time.minute;
}

export function convertDateToTime(date: Date | moment.Moment | null | undefined) {
  if (date == null) return null;

  const momentValue = moment(date);
  return { hour: momentValue.hours(), minute: momentValue.minutes() };
}

export function setTime(value: moment.Moment, time: Time) {
  return moment(value).hour(time.hour).minute(time.minute).second(0).millisecond(0);
}

export function convertDateAndTimeToDate(
  date: Date | moment.Moment | string | null | undefined,
  time: Date | moment.Moment | null | undefined,
) {
  if (date == null || time == null) return null;

  const momentDate = moment(date);
  const momentTime = convertDateToTime(time);
  return momentDate.set('hour', momentTime!.hour).set('minutes', momentTime!.minute);
}
