import { Col, Row, Space, Typography } from 'antd';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { EventWrapperProps } from 'react-big-calendar';
import { FORMATS, calendarLocalizer } from '../calendarLocalizer';
import { CalendarEvent } from '../calendarTypes';
import styles from '../Calendar.module.scss';
import React from 'react';
import { formatters } from '@/core';
import { useTranslation } from 'react-i18next';

function EventContent(props: { event: CalendarEvent; time: boolean }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'calendars.event' });
  const { event, time } = props;
  const { name, assignees } = event.original;

  return (
    <div
      className={classNames(
        styles.event,
        {
          [styles.time]: time,
        },
        'rbc-event',
      )}
    >
      <div className={styles.content}>
        <Row wrap={false}>
          <Col flex="auto">
            <Space direction="vertical" className={styles.name}>
              <span>{name}</span>
            </Space>
            <div className={styles.time}>
              {calendarLocalizer.format(event.start!, FORMATS.eventTime)} -{' '}
              {calendarLocalizer.format(event.end!, FORMATS.eventTime)}
            </div>
          </Col>
        </Row>
        {assignees.length > 0 && (
          <Typography.Paragraph className="mt-2">
            {assignees.map(formatters.name).join(', ')}
          </Typography.Paragraph>
        )}
        {assignees.length === 0 && (
          <Typography.Paragraph italic className="mt-2">
            {t('noAssignees')}
          </Typography.Paragraph>
        )}
      </div>
    </div>
  );
}

function calculateStyle(style?: { height: number; width: number; top: number; xOffset: number }) {
  if (!style || style.xOffset === 0) return style;

  const newXOffset = style.xOffset / 3;

  return {
    ...style,
    xOffset: newXOffset,
    width: style.width + (style.xOffset - newXOffset),
  };
}

export function CustomEventWrapper(props: PropsWithChildren<EventWrapperProps<CalendarEvent>>) {
  const { event, style, children: childrenProp } = props;
  const children = childrenProp as React.ReactElement<any>;
  const eventStyles = calculateStyle(children.props.style);
  const childrenContentProps = children.props.children.props;

  const styles =
    eventStyles != null
      ? {
          ...children.props.children.props.style,
          width: `${eventStyles.width}%`,
          left: `${eventStyles.xOffset}%`,
        }
      : children.props.children.props.style;

  const newElement = React.cloneElement(children, {
    children: (
      <div
        className="rbc-event"
        style={styles}
        onClick={childrenContentProps.onClick}
        onDoubleClick={childrenContentProps.onDoubleClick}
        onKeyPress={childrenContentProps.onKeyPress}
      >
        <EventContent event={event} time={!!style} />
      </div>
    ),
    className: children.props.className,
    style: styles,
  });

  return newElement;
}
