import { Navigate, Route, Routes } from 'react-router-dom';
import { ActivityDetailsPage } from './Activity.Details/ActivityDetailsPage';
import { ActivityListPage } from './Activity.List/ActivityListPage';
import { AboutUsPage } from './Misc.AboutUs/AboutUsPage';
import { Error404Page } from './Misc.Errors.404/Error404Page';
import { TermsPage } from './Misc.Terms/TermsPage';
import { UserChangePasswordPage } from './Users.ChangePassword/UserChangePasswordPage';
import { UserDetailsPage } from './Users.Details/UserDetailsPage';
import { UserAddPage } from './Users.Add/UserAddPage';
import { UsersListPage } from './Users.List/UsersListPage';
import { UserProfilePage } from './Users.Profile/UserProfilePage';
import { DemoPage } from './Demo/DemoPage';
import { NotificationsListPage } from './Notifications.List/NotificationsListPage';
import { featureFlags } from '@/core';
import { CalendarPage } from './Calendar/CalendarPage';

export function Pages() {
  return (
    <Routes>
      <Route path="/terms-and-conditions" element={<TermsPage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/me" element={<UserProfilePage />} />
      <Route path="/change-password" element={<UserChangePasswordPage />} />
      <Route path="/users" element={<UsersListPage />} />
      <Route path="/users/invite" element={<UserAddPage />} />
      <Route path="/users/:id" element={<UserDetailsPage />} />
      {featureFlags.isEnabled('ActivityPage') && (
        <>
          <Route path="/activity" element={<ActivityListPage />} />
          <Route path="/activity/:id" element={<ActivityDetailsPage />} />
        </>
      )}
      <Route path="/demo" element={<DemoPage />} />
      <Route path="/notifications" element={<NotificationsListPage />} />
      <Route path="/calendar" element={<CalendarPage />} />
      <Route path="*" element={<Error404Page />} />
      <Route path="/" element={<Navigate to="/me" />} />
    </Routes>
  );
}
