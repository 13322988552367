import { formatters, Role } from '@/core';
import { useSelector } from 'react-redux';
import { usersApi } from './usersApi';

function createUserContextSelectors(state: any) {
  const context = usersApi.endpoints.getContext.select()(state).data!;

  const selectors = Object.assign({}, context, {
    hasRole: (role: Role) => context.role === role,

    get name() {
      return formatters.name(context);
    },
  });

  return selectors;
}

export function useUserContextSelector<TResult>(
  select: (selectors: ReturnType<typeof createUserContextSelectors>) => TResult,
) {
  return useSelector((state) => {
    return select(createUserContextSelectors(state));
  });
}
