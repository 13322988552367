import { spinner, YesNo, DEFAULT_PAGING, DateTimeValue } from '@/components';
import { useAppActivityList } from '@/core';
import { Activity } from '@webinex/activity';
import { FilterRule } from '@webinex/asky';
import { Table, Tag } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getActivityKindKey } from '@/views';
import { ActivityTableSearch } from './Search/ActivityTableSearch';

function useColumns() {
  const { t } = useTranslation();
  return useMemo<ColumnType<Activity>[]>(() => {
    return [
      {
        title: t('activity.table.kind'),
        key: 'kind',
        dataIndex: 'kind',
        render: (_, { kind, id }) => (
          <NavLink to={`/activity/${id}`}>
            {t(`activity.kind.${getActivityKindKey(kind)}`, { defaultValue: kind })}
          </NavLink>
        ),
      },
      {
        title: t('activity.table.performedAt'),
        key: 'performedAt',
        dataIndex: 'performedAt',
        render: (_, { performedAt }) => <DateTimeValue value={performedAt} />,
      },
      {
        title: t('activity.table.user'),
        key: 'userName',
        dataIndex: 'userName',
      },
      {
        title: t('activity.table.success'),
        key: 'success',
        dataIndex: 'success',
        render: (_, { success }) => (
          <Tag color={success ? 'success' : 'warning'}>
            <YesNo>{success}</YesNo>
          </Tag>
        ),
      },
      {
        title: t('activity.table.body'),
        key: 'body',
        render: (_, { kind, values }) => (
          <Trans i18nKey={`activity.body.${getActivityKindKey(kind)}`} values={values}>
            {kind}
          </Trans>
        ),
      },
    ];
  }, [t]);
}

function useViewState() {
  const { state, fetch } = useAppActivityList();
  const [paging, setPaging] = useState<TablePaginationConfig>(DEFAULT_PAGING);
  const [filter, setFilter] = useState<FilterRule>({
    operator: '=',
    fieldId: 'parentId',
    value: null,
  });

  useEffect(() => {
    spinner.show();

    fetch({
      paging: {
        skip: (paging.current! - 1) * paging.pageSize!,
        take: paging.pageSize!,
      },
      sort: { fieldId: 'performedAt', dir: 'desc' },
      includeTotal: true,
      filter,
    }).finally(spinner.hide);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, filter]);

  return {
    items: Object.values(state.itemById),
    onTableChange: setPaging,
    paging: { ...paging, total: state.total },
    onFilterChange: setFilter,
  };
}

export function ActivityTablePanel() {
  const { items, onTableChange, paging, onFilterChange } = useViewState();
  const columns = useColumns();

  return (
    <div>
      <ActivityTableSearch className="mb-5" onSubmit={onFilterChange} />
      <Table<Activity>
        columns={columns}
        rowKey={(row) => row.id}
        dataSource={items}
        onChange={onTableChange}
        pagination={paging}
      />
    </div>
  );
}
