import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserDetailsActionButton, UserDetailsPanel } from '@/views';
import { Attach, Auth, Page } from '@/components';
import { formatters, useAppClippo } from '@/core';
import { useGetUserQuery } from '@/redux';

function _UserDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams<'id'>();
  const { data } = useGetUserQuery({ id: id! });
  const title = data ? formatters.name(data) : t('fetchFallbackTitle');

  const clippo = useAppClippo({
    ownerId: id,
    ownerType: 'UserFiles',
    defer: false,
  });

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href="/users">{t('users.title')}</Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card title={title} actions={<UserDetailsActionButton id={id!} />}>
          <UserDetailsPanel id={id!} />
        </Page.Card>
        <Page.Card title="Files">
          <Attach {...clippo} />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}

export const UserDetailsPage = Auth.Banner('ADMIN')(_UserDetailsPage);
