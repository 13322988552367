import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Yup from 'yup';
import * as locale from 'yup/lib/locale';
import { merge } from 'lodash';

import common from '@/assets/en.yaml';
import activity from '@/assets/activity.en.yaml';
import errors from '@/assets/errors.en.yaml';
import nav from '@/assets/nav.en.yaml';
import users from '@/assets/users.en.yaml';
import enums from '@/assets/enums.en.yaml';
import notifications from '@/assets/notifications.en.yaml';
import calendars from '@/assets/calendars.en.yaml';

function initializeI18n() {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: merge(common, activity, errors, nav, users, enums, notifications, calendars),
      },
    },

    lng: 'en',

    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });
}

function initializeYupLocalization() {
  const keysLocale = convertToKeys(locale, []);

  function convertToKeys(obj: any, path: string[]) {
    if (typeof obj === 'string' || typeof obj === 'function') {
      return (args: any) => ({ key: path.join('.'), args });
    }

    const result: Record<string, any> = {};
    Object.keys(obj)
      .filter((x) => x !== 'default')
      .forEach((key) => (result[key] = convertToKeys(obj[key], [...path, key])));
    return result;
  }

  Yup.setLocale(keysLocale as locale.LocaleObject);
}

initializeI18n();
initializeYupLocalization();
