import { Table } from '@/components';
import { NotificationListItemState } from '@webinex/wispo';
import { TablePaginationConfig } from 'antd';
import { useState } from 'react';
import { useNotificationsListColumns } from './useNotificationsListColumns';
import { useNotificationsListData } from './useNotificationsListData';

const DEFAULT_PAGING: TablePaginationConfig = {
  pageSize: 20,
  current: 1,
  pageSizeOptions: ['10', '20', '50'],
};

export function NotificationsListPanel() {
  const [paging, setPaging] = useState<TablePaginationConfig>(DEFAULT_PAGING);
  const { isPending, markRead, totalMatch, items } = useNotificationsListData(paging);
  const columns = useNotificationsListColumns(markRead);

  return (
    <Table<NotificationListItemState>
      loading={isPending}
      columns={columns}
      dataSource={items}
      onChange={setPaging}
      pagination={{ ...paging, total: totalMatch }}
      rowKey={(row) => row.id}
    />
  );
}
