import * as Yup from 'yup';
import { useCallback } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import { FilterRule } from '@webinex/asky';
import { Form } from '@/components';
import { useUserLookupSource } from '@/views';
import { useActivityKindLookupSource } from './useActivityKindLookupSource';
import { ACITIVITY_FILTER_INITIAL_VALUE, FormActivityValueFilters } from './FormActivityValueFilters';
import { mapActivitySearchToFilterRule } from './mapActivitySearchToFilterRule';

const SCHEMA = Yup.object({
  userId: Yup.string().nullable(),
  kind: Yup.string().nullable(),
  valueFilters: Yup.array()
    .of(
      Yup.object({
        path: Yup.string()
          .nullable()
          .when('value', {
            is: (value: string) => !isEmpty(value),
            then: (schema) => schema.required(),
          }),
        value: Yup.string().nullable(),
      }),
    )
    .required(),
});

export type ActivitySearchFormValue = Yup.InferType<typeof SCHEMA>;

interface Props {
  onSubmit: (request: FilterRule) => any;
  className?: string;
}

const INITIAL_VALUES: ActivitySearchFormValue = {
  userId: null,
  kind: null,
  valueFilters: [{ ...ACITIVITY_FILTER_INITIAL_VALUE }],
};

function useHandleSubmit(props: Props) {
  const { onSubmit } = props;
  return useCallback(
    (value: ActivitySearchFormValue) => {
      const filterRule = mapActivitySearchToFilterRule(value);
      onSubmit(filterRule);
    },
    [onSubmit],
  );
}

export function ActivityTableSearch(props: Props) {
  const { className } = props;
  const { t } = useTranslation();
  const handleSubmit = useHandleSubmit(props);
  const userDataSource = useUserLookupSource();
  const kindDataSource = useActivityKindLookupSource();

  return (
    <div className={className}>
      <Form.Formik<ActivitySearchFormValue>
        uid="activity-table"
        onSubmit={handleSubmit}
        initialValues={INITIAL_VALUES}
        i18nKeyPrefix="activity.table"
        validationSchema={SCHEMA}
      >
        <Form.AsyncSelect name="userId" dataSource={userDataSource} />
        <Form.AsyncSelect name="kind" dataSource={kindDataSource} />
        <FormActivityValueFilters name="valueFilters" />

        <Row justify="end">
          <Col>
            <Form.Submit icon={<SearchOutlined />} type="primary">
              {t('activity.table.search')}
            </Form.Submit>
          </Col>
        </Row>
      </Form.Formik>
    </div>
  );
}
