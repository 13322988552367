import React, { useCallback, useMemo } from 'react';
import { useChangeActiveMutation, useGetUserQuery, useUnlockUserMutation } from '@/redux';
import { Dropdown, Menu } from 'antd';
import { useSubmitUserDetailsFormik } from './UserDetailsPanel';
import { useTranslation } from 'react-i18next';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { notify } from '@/components';

interface Props {
  id: string;
}

const useToggleActive = ({ id }: Props) => {
  const { data } = useGetUserQuery({ id });
  const { isActive } = data ?? {};
  const [changeActive] = useChangeActiveMutation();

  return useCallback(
    () =>
      changeActive({ id, value: !isActive })
        .unwrap()
        .then(() => notify.success.t(isActive ? 'users.deactivated' : 'users.activated')),
    [id, isActive, changeActive],
  );
};

const useUnlock = ({ id }: Props) => {
  const [unlock] = useUnlockUserMutation();
  return useCallback(() => unlock({ id }), [id, unlock]);
};

function useMenu(props: Props) {
  const { id } = props;
  const { t } = useTranslation();
  const { data } = useGetUserQuery({ id });
  const toggleActive = useToggleActive(props);
  const unlock = useUnlock(props);
  const { isActive, passwordLockedOut } = data ?? {};
  const notNullData = !!data;

  return useMemo<React.ReactElement | null>(() => {
    if (!notNullData) {
      return null;
    }

    const items: ItemType[] = [
      {
        key: 'activate',
        label: t('users.details.' + (isActive ? 'deactivate' : 'activate')),
        onClick: toggleActive,
      },
      passwordLockedOut && {
        key: 'unlock',
        onClick: unlock,
        label: t('users.details.unlock'),
      },
    ].filter((x) => !!x) as ItemType[];

    return <Menu items={items} />;
  }, [t, isActive, unlock, passwordLockedOut, toggleActive, notNullData]);
}

export function UserDetailsActionButton(props: Props) {
  const { id } = props;
  const { t } = useTranslation();
  const submit = useSubmitUserDetailsFormik();
  const { data } = useGetUserQuery({ id });
  const menu = useMenu(props);

  if (!data) {
    return null;
  }

  return (
    <Dropdown.Button type="primary" trigger={['click']} onClick={submit} overlay={menu!}>
      {t('save')}
    </Dropdown.Button>
  );
}
