import { useMemo } from 'react';
import { DateLocalizer } from 'react-big-calendar';
import { DndCalendarUtils } from './dndCalendarUtils';

export function useDndCalendarLocalizerPatch(localizer: DateLocalizer): DateLocalizer {
  return useMemo(() => {
    const eventTimeRangeFormat: DateLocalizer['formats']['eventTimeRangeFormat'] | undefined = localizer
      .formats.eventTimeRangeFormat
      ? (...args) => {
          const [range, ...rest] = args;
          const newRange = { ...range, end: DndCalendarUtils.convertInclusiveEndToExclusive(range.end) };
          return localizer.formats.eventTimeRangeFormat!.apply(null, [newRange, ...rest]);
        }
      : undefined;

    const selectRangeFormat: DateLocalizer['formats']['selectRangeFormat'] | undefined = localizer.formats
      .selectRangeFormat
      ? (...args) => {
          const [range, ...rest] = args;
          const newRange = { ...range, end: DndCalendarUtils.convertInclusiveEndToExclusive(range.end) };
          return localizer.formats.selectRangeFormat!.apply(null, [newRange, ...rest]);
        }
      : undefined;

    return {
      ...localizer,
      formats: {
        ...localizer.formats,
        selectRangeFormat,
        eventTimeRangeFormat,
      },
    } as DateLocalizer;
  }, [localizer]);
}
