import { useAppListState, useAppUnreadCount } from '@/core/wispoClient';
import {
  NotificationListItemState,
  FilterRule,
  NotificationField,
  NotificationListField,
  SortRule,
} from '@webinex/wispo';
import { Button, List, Space } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocalizedNotification } from '../Notifications';

import classes from './NotificationAlertOverlay.module.scss';

function ListItem({
  item,
  onMarkRead,
}: {
  item: NotificationListItemState;
  onMarkRead: (id: string) => any;
}) {
  const { id } = item;
  const handleRead = useCallback(() => onMarkRead(id), [id, onMarkRead]);
  const { t } = useTranslation();
  const markRead = item.markRead;
  const busy = markRead.isPending || markRead.isReloading;

  const { subject, body } = useLocalizedNotification(item);

  return (
    <div className="p-2">
      <div>
        <div className="text-multiline">{subject}</div>
        <h6 className="text-multiline">{body}</h6>
      </div>
      <div className="mt-2">
        <Button type="link" className="text-small" onClick={handleRead}>
          {!busy && t('notifications.alert.markRead')}
          {busy && t('notifications.alert.markingRead')}
        </Button>
      </div>
    </div>
  );
}

const FILTER: FilterRule = {
  operator: '=',
  fieldId: NotificationField.IS_READ,
  value: false,
};

const SORT: SortRule[] = [{ fieldId: NotificationField.CREATED_AT, dir: 'desc' }];

const INCLUDE = [NotificationListField.Items, NotificationListField.TotalUnread];

export function NotificationAlertOverlay() {
  const { t } = useTranslation();
  const unread = useAppUnreadCount();
  const push = useNavigate();
  const { isPending, items, markRead, markViewRead } = useAppListState({
    take: 10,
    filter: FILTER,
    sort: SORT,
    include: INCLUDE,
  });

  const handleMarkRead = (id: string) => {
    markRead([id]);
  };

  return (
    <div className={classes.container}>
      <h6 className="bg-light p-2 mb-0">{t('notifications.alert.title', { unreadCount: unread })}</h6>

      <hr className="my-0" />

      <List
        className={classes.list}
        dataSource={items}
        loading={isPending}
        renderItem={(item: NotificationListItemState) => <ListItem item={item} onMarkRead={handleMarkRead} />}
      />

      <hr className="my-0" />

      <div className="bg-light p-2 text-center text-small">
        <Space>
          <Button type="link" onClick={markViewRead}>
            {t('notifications.alert.markViewRead')}
          </Button>
          <Button type="link" onClick={() => push(`/notifications`)}>
            {t('notifications.alert.showAll')}
          </Button>
        </Space>
      </div>
    </div>
  );
}
