import { enumObject, EnumObjectValue } from '@/utils';

export const TimeUnit = enumObject('TimeUnit', ['Minute', 'Hour', 'Day', 'Week'] as const);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TimeUnit = EnumObjectValue<typeof TimeUnit>;

export function convertTimeUnitsToMinutes(unit: TimeUnit, count: number) {
  switch (unit) {
    case 'Minute':
      return count;
    case 'Hour':
      return count * 60;
    case 'Day':
      return count * 24 * 60;
    case 'Week':
      return count * 7 * 24 * 60;
    default:
      throw new Error(`Unknown time unit ${unit}`);
  }
}
