import { EnumObject, enumValues, useEnumTranslation } from '@/utils';
import { FormsRadio, FormsRadioProps } from './FormsRadio';
import { useMemo } from 'react';

export type FormsEnumRadioProps<T extends Readonly<string[]>> = Omit<FormsRadioProps<string>, 'options'> & {
  type: EnumObject<T>;
};

function useOptions<T extends Readonly<string[]>>(props: FormsEnumRadioProps<T>) {
  const { type } = props;
  const et = useEnumTranslation();
  return useMemo(() => enumValues(type).map((x) => ({ value: x, label: et(type, x) })), [et, type]);
}

export function FormsEnumRadio<T extends Readonly<string[]>>(props: FormsEnumRadioProps<T>) {
  const { type, ...formsRadioProps } = props;
  const options = useOptions(props);
  return <FormsRadio {...formsRadioProps} options={options} />;
}
