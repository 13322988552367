export const DATE_FORMAT_MOMENT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT_MOMENT = 'DD/MM/YYYY HH:mm';
export const MAX_FILE_SIZE = 18874368; // 18 mb
export const DATE_RANGE_FORMAT_MOMENT = 'DD MMMM, YYYY';
export const TIME_FORMAT_MOMENT = 'h:mm A';
export const HOUR_WITH_LEADING_ZERO_FORMAT_MOMENT = 'hh A';
export const HOUR_FORMAT_MOMENT = 'h A';
export const HOUR_MINUTES_FORMAT_MOMENT = 'h:mm A';
export const WEEK_DAY_FORMAT_MOMENT = 'ddd';
export const MONTH_DAY_FORMAT_MOMENT = 'DD';
