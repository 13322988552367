import { enumObject, EnumObjectValue, enumValues } from '@/utils';

export const Weekday = enumObject('Weekday', [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const);

const VALUES = enumValues(Weekday);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Weekday = EnumObjectValue<typeof Weekday>;

export function addWeekday(weekday: Weekday, number: number): Weekday {
  const index = VALUES.indexOf(weekday);
  number = number % VALUES.length;
  const newIndex = (index + number) % VALUES.length;
  return VALUES.at(newIndex)!;
}
