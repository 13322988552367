import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarCropperProps } from './AvatarCropper.props';

const Component = React.lazy(() => import('./AvatarCropper'));

export const LazyAvatarCropper = (props: AvatarCropperProps) => {
  const { t } = useTranslation();

  return (
    <Suspense fallback={<div>{t('fetchFallbackTitle')}</div>}>
      <Component {...props} />
    </Suspense>
  );
};
