import { Page } from '@/components';
import { NotificationsListPanel } from '@/views';
import { useTranslation } from 'react-i18next';

export function NotificationsListPage() {
  const { t } = useTranslation(undefined, { keyPrefix: 'notifications.list' });

  return (
    <Page htmlTitle={t('title')}>
      <Page.Paths>
        <Page.Path>{t('title')}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card title={t('title')}>
          <NotificationsListPanel />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
