import { useFormLabel } from './FormsI18nContext';
import { UsaStates } from '@/core';
import { FormsEnumSelect } from '@/components/Forms/FormsEnumSelect';

export type FormsStateSelectProps = {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
};

export const FormsStateSelect = (props: FormsStateSelectProps) => {
  const { name, label: labelOverride, required } = props;
  const label = useFormLabel(name, labelOverride);

  return <FormsEnumSelect name={name} type={UsaStates} required={required} label={label} />;
};
