import { notify, spinner } from '@/components';
import { CodedException } from '@/core';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';

export const RtkqNotifyError: unique symbol = Symbol();
export const RtkqSpin: unique symbol = Symbol();

export class RtkqCatchedError extends Error {
  constructor(public inner: any) {
    super();
  }
}

export type RtkqRequestConfig = {} & {
  /**
   * Indicates should rtkq display spinner while executing request
   * Default value is true
   */
  [RtkqSpin]?: boolean;

  /**
   * Indicates should rtkq display notification when request fails
   * Default value is true
   */
  [RtkqNotifyError]?: boolean;
};

export type RtkqRequest<TData> = TData extends void ? RtkqRequestConfig | void : TData & RtkqRequestConfig;

type RtkqResult<TResult, TMeta = unknown> = MaybePromise<QueryReturnValue<TResult, any, TMeta>>;

type RtkqFnArgs<TResult, TMeta = unknown> =
  | (() => Promise<TResult>)
  | {
      query: () => Promise<TResult>;
      meta?: (result: QueryReturnValue<TResult, any, TMeta>) => TMeta;
    };

interface RtkqFnConfigured {
  exec: <TResult, TMeta = unknown>(args: RtkqFnArgs<TResult, TMeta>) => RtkqResult<TResult>;
}

interface RtkqFn {
  <TResult, TMeta = unknown>(args: RtkqFnArgs<TResult, TMeta>): RtkqResult<TResult>;

  (config: RtkqRequestConfig | void): RtkqFnConfigured;
}

function rtkqFn<TResult, TMeta>(
  args: RtkqFnArgs<TResult, TMeta>,
  catchFn: (error: any) => any,
): RtkqResult<TResult> {
  const { query, meta } = typeof args === 'function' ? { query: args, meta: undefined } : args;

  return query()
    .then((data) => ({ data, meta: meta && meta({ data }) }))
    .catch(catchFn)
    .catch((error) => ({ error, meta: meta && meta({ error }) }));
}

function _rtkq(config: RtkqRequestConfig): RtkqFnConfigured {
  return {
    exec: <TResult, TMeta = unknown>(args: RtkqFnArgs<TResult, TMeta>): RtkqResult<TResult> => {
      const spin: boolean = config ? config[RtkqSpin] ?? true : true;
      const errorNotify: boolean = config ? config[RtkqNotifyError] ?? true : true;

      spin && spinner.show();

      return Promise.resolve(
        rtkqFn(args, (error) => {
          const coded = CodedException.from(error);

          if (errorNotify) {
            notify.error.coded(coded);
            throw new RtkqCatchedError(error);
          }

          throw error;
        }),
      ).finally(() => {
        spin && spinner.hide();
      }) as RtkqResult<TResult>;
    },
  };
}

export const rtkq: RtkqFn = _rtkq as any;
