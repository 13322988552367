import { ReactChildren } from '@/utils';
import { Col, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
import styles from './Title.module.scss';

export interface TitleProps {
  className?: string;
  level?: 0 | 1 | 2;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  inlineActions?: boolean;
  spaceActions?: boolean;
}

function _Title(props: TitleProps) {
  const { className, level = 0, children, actions: actionsProp, inlineActions = false, spaceActions } = props;

  const containerClassName = classNames(className, styles.container, `level-${level}`);

  const {
    children: actions = actionsProp,
    inline = inlineActions,
    space = spaceActions,
  } = ReactChildren.of(children).find(TitleActions).props() ?? {};

  const title = (
    <Typography.Title level={2} className={styles.title}>
      {children}
    </Typography.Title>
  );

  if (!actions) {
    return <div className={containerClassName}>{title}</div>;
  }

  if (inline) {
    const actionsItem = space ? <Space>{actions}</Space> : <span>{actions}</span>;

    return (
      <Space className={containerClassName} size="small" align="center">
        {title} {actionsItem}
      </Space>
    );
  }

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <div className={containerClassName}>{title}</div>
      </Col>
      <Col>{actions}</Col>
    </Row>
  );
}

const TitleActions: FC<PropsWithChildren<{ inline?: boolean; space?: boolean }>> = () => <></>;
export const Title = Object.assign(_Title, { Actions: TitleActions });
