import { Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Key } from 'antd/lib/table/interface';
import { TableColumnType } from '../Table';
import { exportToExcel } from './exportToExcel';

export function useExcelExport<T>(
  columns: TableColumnType<T>[],
  dataSource?: readonly T[],
  className?: string,
  exceptColumns: Key[] = ['actions'],
) {
  const { t } = useTranslation();

  const handleExcelExport = () => {
    const exportColumns = columns!.filter((x) => !exceptColumns.includes(x.key));
    exportToExcel(exportColumns, dataSource);
  };

  return {
    exportAction: (
      <Button
        className={className}
        onClick={handleExcelExport}
        icon={<FileExcelOutlined />}
        disabled={!dataSource || dataSource.length === 0}
      >
        {t('downloadExcel')}
      </Button>
    ),
  };
}
