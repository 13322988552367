import { useField } from 'formik';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';
import { FC, useCallback, useEffect, useState, ReactNode } from 'react';
import { Input } from 'antd';
import {
  convertDateToTime,
  convertMinutesToTime,
  convertTimeToMinutes,
  convertTimeToTimeInput,
  isEqualTime,
  parseTimeText,
  Time,
  setTime,
  addTimeOffset,
} from '@/core';
import moment from 'moment';

export type FormsTimeProps = {
  name: string;
  label?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  mode?: 'minutes' | 'moment';
  date?: moment.Moment | Date | string;
};

interface Context {
  date?: moment.Moment | Date | string;
  formValue: string | number | moment.Moment | null;
}

const converters: Record<
  Required<FormsTimeProps>['mode'],
  {
    toTime: (formValue: string | number | moment.Moment | null) => Time | null;
    toFormValue: (time: Time | null, context: Context) => string | number | moment.Moment | null;
  }
> = {
  minutes: {
    toTime: (formValue) =>
      formValue != null
        ? addTimeOffset(convertMinutesToTime(formValue as number)!, moment().utcOffset())
        : null,
    toFormValue: (time) => (time ? convertTimeToMinutes(addTimeOffset(time, -moment().utcOffset())) : null),
  },

  moment: {
    toTime: (formValue) =>
      formValue ? convertDateToTime(moment(formValue).utcOffset(moment().utcOffset())) : null,
    toFormValue: (time, { formValue, date = moment() }) => {
      if (!time) return null;

      const local = moment(formValue ?? date).utcOffset(moment().utcOffset());
      const localResult = setTime(local, time);
      return localResult.utc();
    },
  },
};

function useInput(props: FormsTimeProps) {
  const { name, mode, date } = props;
  const [{ value: formValue }, , { setValue, setTouched }] = useField<string | number | null | moment.Moment>(
    name,
  );

  const [input, setInput] = useState(convertTimeToTimeInput(converters[mode!].toTime(formValue)) ?? '');

  useEffect(() => {
    if (!isEqualTime(converters[mode!].toTime(formValue), parseTimeText(input))) {
      setInput(convertTimeToTimeInput(converters[mode!].toTime(formValue)) ?? '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      setInput(inputValue);

      const parsed = parseTimeText(inputValue);
      const context = { formValue, date };
      const result = parsed == null ? null : converters[mode!].toFormValue(parsed, context);

      setTouched(true);
      setValue(result, true);
    },
    [setValue, setTouched, formValue, mode, date],
  );

  return { input, onChange };
}

export const FormsTime: FC<FormsTimeProps> = (props) => {
  const { name, label: labelOverride, required, disabled, className, placeholder } = props;
  const [field] = useField(name);
  const label = useFormLabel(name, labelOverride);
  const { input, onChange } = useInput(props);

  return (
    <FormsGroup label={label} name={name} required={required}>
      <Input
        {...field}
        value={input}
        onChange={onChange}
        className={className}
        disabled={disabled}
        placeholder={placeholder}
      />
    </FormsGroup>
  );
};

FormsTime.defaultProps = {
  mode: 'moment',
};
