import { PropsWithChildren } from 'react';
import { FeatureFlag, featureFlags } from '@/core';

export const Feature = {
  IsEnabled: ({ feature, children }: PropsWithChildren<{ feature: FeatureFlag }>) => {
    const isEnabled = featureFlags.isEnabled(feature);

    if (isEnabled) {
      return <>{children}</>;
    }

    return <></>;
  },
  IsNotEnabled: ({ feature, children }: PropsWithChildren<{ feature: FeatureFlag }>) => {
    const isEnabled = featureFlags.isEnabled(feature);

    if (!isEnabled) {
      return <>{children}</>;
    }

    return <></>;
  },
};
