import styles from './CalendarPage.module.scss';
import { useTranslation } from 'react-i18next';
import { Page } from '@/components';
import { CalendarPanel } from '@/views';

export function CalendarPage() {
  const { t } = useTranslation();

  return (
    <Page htmlTitle={t('calendars.title')} className={styles.page}>
      <Page.Body span={{ xs: 22, offset: 1 }}>
        <CalendarPanel />
      </Page.Body>
    </Page>
  );
}
