import axios, { AxiosError } from 'axios';
import { guard } from '@/utils';

const CODED_EXCEPTION_SYMBOL = Symbol('CODED_EXCEPTION');

export interface CodedExceptionResponse {
  code: string;
  defaultMessage: string;
  payload: any;
}

export class CodedException {
  public code: string;
  public defaultMessage: string | undefined;
  public payload: any;

  constructor(code: string, defaultMessage: string | undefined, payload: any) {
    this.code = guard.notNull(code, 'code');
    this.defaultMessage = defaultMessage;
    this.payload = payload;
    (this as any)[CODED_EXCEPTION_SYMBOL] = true;
  }

  public static from(error: any) {
    if (error[CODED_EXCEPTION_SYMBOL] === true) {
      return error as CodedException;
    }

    if (axios.isAxiosError(error)) {
      return this.fromAxiosError(error);
    }

    return this.unexpected();
  }

  private static fromAxiosError(error: AxiosError<any>) {
    const headerValue = error.response?.headers['x-coded-failure'];
    const data = headerValue != null ? JSON.parse(headerValue) : headerValue;

    if (data == null || !this.isCodedExceptionResponse(data)) {
      return this.unexpected();
    }

    return this.fromCodedExceptionResponse(data);
  }

  private static isCodedExceptionResponse(data: any): data is CodedExceptionResponse {
    return data != null && data.hasOwnProperty('code');
  }

  private static fromCodedExceptionResponse(data: CodedExceptionResponse) {
    return new CodedException(data.code, data.defaultMessage, data.payload);
  }

  private static unexpected() {
    return new CodedException('UNEXP', undefined, null);
  }
}
