import { StyledRow, spinner, YesNo, TextBox, Title, DateTimeValue } from '@/components';
import { useAppActivity } from '@/core';
import { Col } from 'antd';
import { memo, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getActivityKindKey } from '@/views';
import { ActivityOperationList } from './ActivityOperationList';

export interface ActivityDetailsPanelProps {
  id: string;
}

const Values = memo(({ values }: { values: any }) => {
  return <pre className="codeblock p-2 mt-2">{JSON.stringify(values, undefined, 2)}</pre>;
});

export function ActivityDetailsPanel(props: ActivityDetailsPanelProps) {
  const { id } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'activity.details' });
  const { state, fetch } = useAppActivity(id);
  const { value } = state;

  useEffect(() => {
    spinner.show();
    fetch().finally(spinner.hide);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (value == null) {
    return null;
  }

  const { kind, performedAt, userName, success, values, operationId } = value;

  return (
    <div>
      <Title level={2}>{t('title')}</Title>
      <StyledRow>
        <Col span={12}>
          <TextBox label={t('kind')}>
            <Trans i18nKey={`activity.kind.${getActivityKindKey(kind)}`}>{kind}</Trans>
          </TextBox>
        </Col>
        <Col span={12}>
          <TextBox label={t('performedAt')}>
            <DateTimeValue value={performedAt} />
          </TextBox>
        </Col>
        <Col span={12}>
          <TextBox label={t('user')}>{userName}</TextBox>
        </Col>
        <Col span={12}>
          <TextBox label={t('success')}>
            <YesNo>{success}</YesNo>
          </TextBox>
        </Col>
        <Col span={24}>
          <TextBox label={t('body')}>
            <Trans i18nKey={`activity.body.${getActivityKindKey(kind)}`} values={values}>
              {kind}
            </Trans>
          </TextBox>
        </Col>
        <Col span={24}>
          <TextBox label={t('values')}>
            <Values values={values} />
          </TextBox>
        </Col>
      </StyledRow>

      <Title level={2}>{t('operation.title')}</Title>
      <ActivityOperationList operationId={operationId} />
    </div>
  );
}
