import {
  ColumnFilterItem,
  FilterDropdownProps,
  FilterSearchType,
  FilterValue,
} from 'antd/lib/table/interface';
import { enumName, EnumObject } from '@/utils';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef } from 'react';
import i18next from 'i18next';
import { DateTimeRangeFilter } from './DateTimeRangeFilter';
import { NumberRangeFilter } from './NumberRangeFilter';
import { isArray } from 'lodash';
import { PlainTextFilter } from './PlainTextFilter';

export function useEnumFilterOptionFactory() {
  const { t } = useTranslation();
  const cache = useRef<Record<string, ColumnFilterItem[]>>({});

  return useCallback(
    <TEnum extends Readonly<string[]>>(enumType: EnumObject<TEnum>, values?: TEnum[number][]) => {
      const name = enumName(enumType);
      const cacheKey = values ? `${name}-${values.slice().sort().join('-')}` : name;

      if (cache.current[cacheKey]) {
        return cache.current[cacheKey];
      }

      values = values ?? Object.values(enumType);
      const options = values.map((value) => ({
        text: t(`enums.${name}.${value as string}`),
        value: value as string,
      }));

      cache.current[cacheKey] = options;
      return options;
    },
    [t, cache],
  );
}

const YES_NO_FILTERS: ColumnFilterItem[] = [
  { text: i18next.t<string>('yesNo.true'), value: true },
  { text: i18next.t<string>('yesNo.false'), value: false },
];

export interface FiltersColumnDataType<TRecord> {
  filters?: ColumnFilterItem[];
  filterDropdown?: React.ReactNode | ((props: FilterDropdownProps) => React.ReactNode);
  filterMultiple?: boolean;
  filteredValue?: FilterValue | null;
  defaultFilteredValue?: FilterValue | null;
  filterIcon?: React.ReactNode | ((filtered: boolean) => React.ReactNode);
  filterMode?: 'menu' | 'tree';
  filterSearch?: FilterSearchType;
  onFilter?: (value: string | number | boolean, record: TRecord) => boolean;
  filterDropdownVisible?: boolean;
  onFilterDropdownVisibleChange?: (visible: boolean) => void;
  filterResetToDefaultFilteredValue?: boolean;
}

export function useFiltersFactory<T>() {
  const enumFilterOptionsFactory = useEnumFilterOptionFactory();

  return useMemo(
    () => ({
      plainText: <A extends unknown>(
        callback: (x: T) => A,
        filter?: (filterValue: string, record: T) => boolean,
      ): FiltersColumnDataType<T> => ({
        filterDropdown: PlainTextFilter,
        onFilter: (value, record) =>
          filter ? filter(value.toString(), record) : PlainTextFilter.onFilter(value, callback(record)),
      }),

      yesNo: <A extends unknown>(callback: (x: T) => A): FiltersColumnDataType<T> => ({
        filters: YES_NO_FILTERS,
        onFilter: (value, record) => callback(record) === value,
      }),

      dateTimeRange: <A extends unknown>(callback: (x: T) => A): FiltersColumnDataType<T> => ({
        filterDropdown: DateTimeRangeFilter,
        onFilter: (value, record) => DateTimeRangeFilter.onFilter(value, callback(record)),
      }),

      enumSelect: <A, TEnum extends Readonly<string[]>>(
        callback: (x: T) => A,
        enumType: EnumObject<TEnum>,
        values?: TEnum[number][],
      ): FiltersColumnDataType<T> => ({
        filterSearch: (value: string, record: any) =>
          record.value.toLowerCase().includes(value.toLowerCase()),
        filters: enumFilterOptionsFactory(enumType, values),
        onFilter: (valueInput, record) => {
          const value = valueInput as string;
          const recordValue = callback(record) as unknown as string | null | undefined | string[];

          if (isArray(recordValue)) {
            return recordValue.includes(value);
          }

          return recordValue === value;
        },
      }),

      numberRange: <A extends unknown>(callback: (x: T) => A): FiltersColumnDataType<T> => ({
        filterDropdown: NumberRangeFilter,
        onFilter: (value, record) => NumberRangeFilter.onFilter(value, callback(record)),
      }),
    }),
    [enumFilterOptionsFactory],
  );
}
