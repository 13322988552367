import { useCallback, useState } from 'react';
import { SignUpForm } from './SignUpForm';
import { CodedException, SignUpDto, usersHttp } from '@/core';
import { spinner } from '@/components';

interface Props {
  onSubmitted: () => any;
}

const useSubmit = (props: Props) => {
  const { onSubmitted } = props;
  const [error, setError] = useState<CodedException | undefined>(undefined);

  const onSubmit = useCallback(
    async (values: SignUpDto) => {
      await spinner.wrap(() =>
        usersHttp
          .signUp(values)
          .then(() => onSubmitted())
          .catch((ex) => setError(CodedException.from(ex))),
      );
    },
    [onSubmitted, setError],
  );

  return { error, onSubmit };
};

export function SignUpPanel(props: Props) {
  const { error, onSubmit } = useSubmit(props);

  return (
    <div>
      <div>
        <SignUpForm error={error} onSubmit={onSubmit} />
      </div>
    </div>
  );
}
