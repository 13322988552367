import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { flattenDeep } from 'lodash';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function flattenKeys(items: ItemType[]) {
  const keys: string[] = flattenDeep(
    items.map((x) => [x!.key, (x as any).children?.map((child: any) => child.key) ?? []]),
  );

  return keys;
}

function isMatch(key: string, pathname: string) {
  return pathname === key || pathname.startsWith(key + '/');
}

export function useSelectedNavMenuKeys(items: ItemType[]) {
  const { pathname } = useLocation();

  return useMemo(() => {
    const keys = flattenKeys(items);
    const selected = keys.find((x) => isMatch(x, pathname));
    return selected ? [selected] : [];
  }, [pathname, items]);
}
