import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from './Calendar.module.scss';
import moment, { Moment } from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { DateRange, NavigateAction, SlotInfo, stringOrDate, View } from 'react-big-calendar';
import { CUSTOM_CALENDAR_COMPONENTS } from './custom';
import { calendarLocalizer } from './calendarLocalizer';
import { CalendarEvent } from './calendarTypes';
import { CalendarViewContext } from './CalendarViewContext';
import classNames from 'classnames';
import { DndCalendar } from './DndCalendar';

export interface MoveCalendarEventPayload {
  event: CalendarEvent;
  start: Moment;
  end: Moment;
}

export interface CalendarProps {
  range: DateRange;
  events: CalendarEvent[];
  footer?: React.ReactNode;
  onDoubleClick?: (event: CalendarEvent) => any;
  onRangeChange: (value: DateRange) => any;
  onMove?: (args: MoveCalendarEventPayload) => any;
  onSelectSlot?: (range: DateRange) => any;
}

function convertViewToMomentDuration(view: View) {
  switch (view) {
    case 'week':
      return 'week';
    case 'month':
      return 'month';
    case 'day':
      return 'day';
    default:
      throw new Error(`Unable to convert ${view}`);
  }
}

function convertViewToMomentStartOf(view: View) {
  switch (view) {
    case 'week':
      return 'isoWeek';
    case 'month':
      return 'month';
    case 'day':
      return 'day';
    default:
      throw new Error(`Unable to convert ${view}`);
  }
}

function convertNavigateToRange(newDate: Date, view: View, action: NavigateAction): DateRange {
  if (action === 'TODAY') {
    return {
      start: moment(newDate).startOf(convertViewToMomentStartOf(view)).toDate(),
      end: moment(newDate)
        .startOf(convertViewToMomentStartOf(view))
        .add(1, convertViewToMomentDuration(view))
        .toDate(),
    };
  }

  return {
    start: newDate,
    end: moment(newDate).add(1, convertViewToMomentDuration(view)).toDate(),
  };
}

function useHandleNavigate(props: CalendarProps) {
  const { onRangeChange } = props;
  return useCallback(
    (newDate: Date, view: View, action: NavigateAction) => {
      const range = convertNavigateToRange(newDate, view, action);
      onRangeChange(range);
    },
    [onRangeChange],
  );
}

const VIEWS: View[] = ['week', 'month'];

export function useViews(range: DateRange, onRangeChange: (value: DateRange) => any) {
  const [view, setView] = useState<View>('week');

  const onViewChange = useCallback(
    (view: View) => {
      setView(view);

      const start = moment(range.start).startOf(convertViewToMomentStartOf(view)).toDate();

      const newRange: DateRange = {
        start,
        end: moment(start).add(1, convertViewToMomentDuration(view)).toDate(),
      };

      onRangeChange(newRange);
    },
    [range, onRangeChange],
  );

  return { view, onViewChange };
}

function useOnMove(props: CalendarProps) {
  const { onMove } = props;

  const moveHandler = useCallback(
    (args: { event: CalendarEvent; start: stringOrDate; end: stringOrDate }) => {
      return onMove && onMove({ ...args, start: moment(args.start), end: moment(args.end) });
    },
    [onMove],
  );

  return onMove ? moveHandler : undefined;
}

function useEvents(props: CalendarProps) {
  const { events } = props;

  return useMemo(() => {
    const adjusted = events.map((e) => ({
      ...e,
      end: e.end ? moment(e.end).toDate() : e.end,
    }));

    const hasOvernightEvent = adjusted.some(
      (e) => !moment(e.start!).startOf('date').isSame(moment(e.end).startOf('date')),
    );

    return {
      events: adjusted,
      hasOvernightEvent,
    };
  }, [events]);
}

function useSelectSlot(props: CalendarProps) {
  const { onSelectSlot } = props;

  return useMemo(() => {
    if (!onSelectSlot) return undefined;

    return (slot: SlotInfo) => onSelectSlot({ start: slot.start, end: slot.end });
  }, [onSelectSlot]);
}

export function Calendar(props: CalendarProps) {
  const { range, footer, onDoubleClick, onRangeChange } = props;
  const { view, onViewChange } = useViews(range, onRangeChange);
  const handleNavigate = useHandleNavigate(props);
  const onMove = useOnMove(props);
  const onSelectSlot = useSelectSlot(props);
  const { events, hasOvernightEvent } = useEvents(props);

  return (
    <CalendarViewContext.Provider value={view}>
      <div className={styles.container}>
        <DndCalendar
          date={range.start}
          onNavigate={handleNavigate}
          className={classNames(styles.calendar, {
            '--has-footer': !!footer,
            '--has-overnight-event': hasOvernightEvent,
          })}
          events={events}
          localizer={calendarLocalizer}
          components={CUSTOM_CALENDAR_COMPONENTS}
          view={view}
          views={VIEWS}
          onView={onViewChange}
          showAllEvents
          onEventDrop={onMove}
          resizable={false}
          onDoubleClickEvent={onDoubleClick}
          onSelectSlot={onSelectSlot}
          selectable
        />
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </CalendarViewContext.Provider>
  );
}
