import { NotificationListItemState } from '@webinex/wispo';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const DATA_SEPARATOR = '::';

export function parseNotificationData(data: string): {
  type: string;
  payload: any;
} {
  const separatorIndex = data.indexOf(DATA_SEPARATOR);
  const type = data.substring(0, separatorIndex);
  const payloadJson = data.substring(separatorIndex + DATA_SEPARATOR.length);
  const payload = JSON.parse(payloadJson);
  return { type, payload };
}

export function useLocalizeNotification() {
  const [t, { exists }] = useTranslation();

  return useCallback(
    (notification: NotificationListItemState) => {
      const { type, payload } = parseNotificationData(notification.subject);
      const prefix = `notifications.${type}`;
      const subjectKey = `${prefix}.subject`;
      const bodyKey = `${prefix}.body`;

      return {
        ...notification,
        subject: exists(subjectKey) ? t(subjectKey, payload)! : t('notifications.unknown')!,
        body: exists(bodyKey) ? t(bodyKey, payload)! : '',
      };
    },
    [t, exists],
  );
}

export function useLocalizedNotification(notification: NotificationListItemState) {
  const localize = useLocalizeNotification();
  return useMemo(() => localize(notification), [localize, notification]);
}
