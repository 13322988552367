import moment from 'moment';
import { useCallback, useMemo } from 'react';
import {
  Calendar as BigCalendar,
  CalendarProps as ReactBigCalendarProps,
  Event,
  SlotInfo,
} from 'react-big-calendar';
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import { DndCalendarUtils, DropOrResizeInfo } from './dndCalendarUtils';
import { useDndCalendarLocalizerPatch } from './useDndCalendarLocalizerPatch';

type ReactBigDndCalendarProps<TEvent extends Event> = ReactBigCalendarProps<TEvent, object> &
  withDragAndDropProps<TEvent, object>;

export type DndCalendarProps<TEvent extends Event = Event> = Omit<
  ReactBigDndCalendarProps<TEvent>,
  'max' | 'min' | 'endAccessor'
>;

function useReactBigDndCalendar<TEvent extends Event>() {
  const DndCalendar: React.ComponentType<
    ReactBigCalendarProps<TEvent, object> & withDragAndDropProps<TEvent, object>
  > = useMemo(() => withDragAndDrop(BigCalendar as any) as any, []);

  return DndCalendar;
}

function useOnEventDropPatch<TEvent extends Event>(
  props: DndCalendarProps<TEvent>,
): DndCalendarProps<TEvent>['onEventDrop'] {
  const { onEventDrop } = props;

  return useMemo(() => {
    if (!onEventDrop) {
      return onEventDrop;
    }

    return (info: DropOrResizeInfo<TEvent>) => {
      info = {
        ...info,
        end: moment.min(moment(info.end), moment(info.start).startOf('day').add(1, 'day')).toDate(),
      };
      onEventDrop(info);
    };
  }, [onEventDrop]);
}

function useOnEventResizePatch<TEvent extends Event>(
  props: DndCalendarProps<TEvent>,
): DndCalendarProps<TEvent>['onEventResize'] {
  const { onEventResize } = props;

  return useMemo(() => {
    if (!onEventResize) {
      return onEventResize;
    }

    return (info: DropOrResizeInfo<TEvent>) => {
      info = { ...info, end: DndCalendarUtils.convertInclusiveEndToExclusive(info.end) };
      onEventResize(info);
    };
  }, [onEventResize]);
}

function useOnSelectSlotPatch<TEvent extends Event>(
  props: DndCalendarProps<TEvent>,
): DndCalendarProps<TEvent>['onSelectSlot'] {
  const { onSelectSlot } = props;

  return useMemo(() => {
    if (!onSelectSlot) {
      return onSelectSlot;
    }

    return (slot: SlotInfo) => {
      slot = { ...slot, end: DndCalendarUtils.convertInclusiveEndToExclusive(slot.end) };
      onSelectSlot(slot);
    };
  }, [onSelectSlot]);
}

/**
 * The problem of react-big-calendar that it recognize 00:00:00 as next day event
 * and converts it to full day event. Due to it, we convert 00:00:00 to 23:59:59.999 of previous day
 */
function useEndAccessor<TEvent extends Event>() {
  return useCallback((event: TEvent) => {
    const endMoment = moment(event.end);
    const startOfDay = moment(endMoment).startOf('day');

    return endMoment.isSame(startOfDay) ? moment(event.end).add(-1, 'millisecond').toDate() : event.end!;
  }, []);
}

export function DndCalendar<TEvent extends Event = Event>(props: DndCalendarProps<TEvent>) {
  const _DndCalendar = useReactBigDndCalendar<TEvent>();
  const onEventDrop = useOnEventDropPatch(props);
  const onEventResize = useOnEventResizePatch(props);
  const onSelectSlot = useOnSelectSlotPatch(props);
  const endAccessor = useEndAccessor();
  const localizer = useDndCalendarLocalizerPatch(props.localizer);

  return (
    <_DndCalendar
      {...props}
      onEventDrop={onEventDrop}
      onEventResize={onEventResize}
      onSelectSlot={onSelectSlot}
      endAccessor={endAccessor}
      localizer={localizer}
    />
  );
}
