import { api, rtkq, RtkqRequest } from '@/redux';
import {
  CalendarEvent,
  CancelCalendarEventAppearanceRequest,
  CancelCalendarRecurrentEventLineRequest,
  CreateCalendarEventRequest,
  MoveCalendarEventRequest,
  ReassignCalendarEventRequest,
} from './calendarTypes';
import { calendarHttp } from './calendarHttp';
import { Period } from '@/core';

export const calendarApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCalendarEvents: build.query<CalendarEvent[], RtkqRequest<Period<string>>>({
      queryFn: async (args) => rtkq(args).exec(() => calendarHttp.getAll(args)),
      providesTags: (result = []) => {
        return [
          { type: 'calendar-event' },
          ...result.map(({ id }) => ({ type: 'calendar-event' as const, id: id ?? 'none' })),
          ...result.flatMap((x) => x.assignees).map(({ id }) => ({ type: 'user' as const, id })),
        ];
      },
    }),

    createCalendarEvent: build.mutation<void, RtkqRequest<CreateCalendarEventRequest>>({
      queryFn: (args) => rtkq(args).exec(() => calendarHttp.create(args)),
      invalidatesTags: ['calendar-event'],
    }),

    moveCalendarEvent: build.mutation<void, RtkqRequest<MoveCalendarEventRequest>>({
      queryFn: (args) => rtkq(args).exec(() => calendarHttp.move(args)),
      invalidatesTags: ['calendar-event'],
    }),

    cancelCalendarEventAppearance: build.mutation<void, RtkqRequest<CancelCalendarEventAppearanceRequest>>({
      queryFn: (args) => rtkq(args).exec(() => calendarHttp.cancelAppearance(args)),
      invalidatesTags: ['calendar-event'],
    }),

    cancelCalendarRecurrentEventLine: build.mutation<
      void,
      RtkqRequest<CancelCalendarRecurrentEventLineRequest>
    >({
      queryFn: (args) => rtkq(args).exec(() => calendarHttp.cancelLine(args)),
      invalidatesTags: ['calendar-event'],
    }),

    reassignCalendarEvent: build.mutation<void, RtkqRequest<ReassignCalendarEventRequest>>({
      queryFn: (args) => rtkq(args).exec(() => calendarHttp.reassign(args)),
      invalidatesTags: ['calendar-event'],
    }),
  }),
});

export const {
  useGetCalendarEventsQuery,
  useCreateCalendarEventMutation,
  useMoveCalendarEventMutation,
  useCancelCalendarEventAppearanceMutation,
  useCancelCalendarRecurrentEventLineMutation,
  useReassignCalendarEventMutation,
} = calendarApi;
