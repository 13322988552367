import axios from 'axios';
import { Http } from '../http/http';
import { guard } from '@/utils';
import {
  ChangePasswordDto,
  SignUpDto,
  UpdateProfileDto,
  UpdateUserDto,
  UserContextDto,
  UserDto,
  UserInviteDto,
  UserLookupDto,
} from './userTypes';

class UsersHttp extends Http {
  public async context() {
    const response = await this._axios.get<UserContextDto>(`/api/user/context`);
    return response.data;
  }

  public async signUp(value: SignUpDto) {
    guard.notNull(value, 'value');
    await axios.post(`/api/user/sign-up`, value);
  }

  public async changePassword(value: ChangePasswordDto) {
    guard.notNull(value, 'value');

    const uri = `${window.appSettings.identityServerUrl}/api/user/password`;
    await this._axios.put(uri, value);
  }

  public async invite(value: UserInviteDto) {
    guard.notNull(value, 'value');

    await this._axios.post(`/api/user/invite`, value);
  }

  public async updateProfile(value: UpdateProfileDto) {
    guard.notNull(value, 'value');
    const url = `/api/user/profile`;
    await this._axios.put(url, value);
  }

  public async get(id: string) {
    guard.notNull(id, 'id');

    const url = `/api/user/${id}`;
    const response = await this._axios.get<UserDto>(url);
    return response.data;
  }

  public async update(value: UpdateUserDto) {
    guard.notNull(value, 'value');

    const url = `/api/user/${value.id}`;
    await this._axios.put(url, value);
  }

  public async getAll() {
    const url = `/api/user`;
    const response = await this._axios.get<UserDto[]>(url);
    return response.data;
  }

  public async getLookups() {
    const url = `/api/user/lookup`;
    const response = await this._axios.get<UserLookupDto[]>(url);
    return response.data;
  }

  public async changeActive(id: string, value: boolean) {
    guard.notNull(id, 'id');
    guard.notNull(value, 'value');

    const url = `/api/user/${id}/active`;
    await this._axios.put(url, JSON.stringify(value), {
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  public async unlock(id: string) {
    guard.notNull(id, 'id');

    const url = `/api/user/${id}/unlock`;
    await this._axios.put(url);
  }

  public async exists(email: string) {
    guard.notNull(email, 'email');
    const url = `/api/user/exists/${encodeURI(email)}`;
    const response = await axios.get<boolean>(url);
    return response.data;
  }
}

export const usersHttp = new UsersHttp();
