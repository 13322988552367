import { guard } from '@/utils';
import { AxiosError } from 'axios';

const REVOKED_HEADER = 'x-revoked';
const FORBIDDEN_STATUS = 403;

export const Revoke = {
  is: (error: AxiosError) => {
    guard.notNull(error, 'error');

    return error.response?.status === FORBIDDEN_STATUS && error.response.headers[REVOKED_HEADER] === 'true';
  },
};
