import { Form } from '@/components';
import { CalendarEvent } from '@/redux';
import { useUserLookupSource } from '@/views/Users.Common';
import { Button, Col, Row, Space } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export interface ReassignCalendarEventFormProps {
  event: CalendarEvent;
  onSubmit: (event: CalendarEvent, value: string[], thisAndBeyond: boolean) => any;
  onCancel: () => any;
}

const SCHEMA = Yup.object({
  assignees: Yup.array(Yup.string().required()).required(),
  thisAndBeyond: Yup.bool().required(),
});

type FormValue = Yup.InferType<typeof SCHEMA>;

function useInitialValues(props: ReassignCalendarEventFormProps) {
  const {
    event: { assignees },
  } = props;
  return useMemo<FormValue>(
    () => ({ assignees: assignees.map((x) => x.id), thisAndBeyond: false }),
    [assignees],
  );
}

function useSubmit(props: ReassignCalendarEventFormProps) {
  const { onSubmit, event } = props;
  return useCallback(
    (value: FormValue) => onSubmit(event, value.assignees, value.thisAndBeyond),
    [onSubmit, event],
  );
}

export function ReassignCalendarEventForm(props: ReassignCalendarEventFormProps) {
  // const { onCancel, event } = props;
  const { onCancel } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'calendars.details.reassign' });
  const initialValues = useInitialValues(props);
  const submit = useSubmit(props);
  const userLookupSource = useUserLookupSource();

  return (
    <Form.Formik
      uid="calendars-details-reassign"
      i18nKeyPrefix="calendars.details.reassign"
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={SCHEMA}
    >
      <Form.AsyncSelect name="assignees" mode="multiple" dataSource={userLookupSource} preload />
      {/* {event.recurrentEventId && <Form.Checkbox name="thisAndBeyond" />} */}
      <Row justify="end">
        <Col>
          <Space align="end">
            <Button onClick={onCancel} type="ghost">
              {t('cancel')}
            </Button>
            <Form.Submit type="primary">{t('save')}</Form.Submit>
          </Space>
        </Col>
      </Row>
    </Form.Formik>
  );
}
