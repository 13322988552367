import axios, { AxiosInstance } from 'axios';
import { openid } from '@/core/openid';
import { Revoke } from './revoke';
import { CodedException } from '..';
import { getFeatureFlagsOverridesHeader } from '../featureFlags';

const getAuthHeaderValue = async () => {
  const token = await openid.token();
  return `Bearer ${token}`;
};

const AUTHORIZATION_HEADER = 'Authorization';

export class Http {
  protected _axios: AxiosInstance;

  public static async getAuthHeader() {
    return {
      key: AUTHORIZATION_HEADER,
      value: await getAuthHeaderValue(),
    };
  }

  constructor() {
    this._axios = axios.create();

    this._axios.interceptors.request.use(async (request) => {
      const { key, value } = await Http.getAuthHeader();
      request.headers![key] = value;
      return request;
    });

    this._axios.interceptors.request.use(async (request) => {
      const { key, value } = getFeatureFlagsOverridesHeader();
      request.headers![key] = value;
      return request;
    });

    this._axios.interceptors.response.use(undefined, async (error) => {
      if (error.config && Revoke.is(error)) {
        await openid.renewRevokedToken();
        const { key, value } = await Http.getAuthHeader();
        error.config.headers[key] = value;
        return this._axios.request(error.config);
      }

      throw CodedException.from(error);
    });
  }
}
