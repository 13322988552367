import { array } from '@/utils';
import { LookupOption } from './lookupOption';

export interface LookupSource {
  searchField?: string;
  search: (searchString: string | undefined) => Promise<LookupOption[]>;
}

type EntireLoadFn = () => Promise<LookupOption[]>;
interface EntireLoadOptions {
  load: EntireLoadFn;
  searchField?: string;
}

export const EMPTY_LOOKUP_SOURCE: LookupSource = {
  search: () => Promise.resolve(array.empty<LookupOption>()),
};

export function createEntireLoadLookupSource(fnOrOptions: EntireLoadFn | EntireLoadOptions): LookupSource {
  const options: EntireLoadOptions = typeof fnOrOptions === 'function' ? { load: fnOrOptions } : fnOrOptions;
  const source = new EntireLoadLookupSource(options.load);
  return { search: source.search, searchField: options.searchField };
}

class EntireLoadLookupSource implements LookupSource {
  private _fn: EntireLoadFn;
  private _source: Promise<LookupOption[]>;
  private _resolve!: (value: LookupOption[]) => any;
  private _resolved: boolean = false;
  private _fetching: boolean = false;

  constructor(fn: EntireLoadFn) {
    this._fn = fn;

    this._source = new Promise((resolve) => {
      this._resolve = resolve;
    });
  }

  public search = async (_: string | undefined) => {
    if (this._resolved || this._fetching) {
      return await this._source;
    }

    this._fetching = true;
    this._fn()
      .then(this._resolve)
      .finally(() => (this._fetching = false));

    return this._source;
  };
}
