import { DateTimeValue } from '@/components';
import { Activity } from '@webinex/activity';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getActivityKindKey } from '@/views';
import { Col, Row, Space, Typography } from 'antd';
import { EnterOutlined } from '@ant-design/icons';

interface Props {
  id: string;
  values: Activity[];
}

export function ActivityOperationItem(props: Props) {
  const { id, values } = props;
  const value = values.find((x) => x.id === id)!;
  const child = values.filter((x) => x.parentId === id);

  const { kind, performedAt } = value;

  return (
    <div>
      <div>
        <Typography.Text underline strong>
          <NavLink to={`/activity/${value.id}`}>
            <Trans i18nKey={`activity.kind.${getActivityKindKey(kind)}`}>{kind}</Trans>
          </NavLink>
        </Typography.Text>
        <div>
          <DateTimeValue value={performedAt} className="text-small" />
        </div>
      </div>
      {child.length > 0 && (
        <div className="ms-2 mt-2">
          {child.map((item) => (
            <Row key={item.id} wrap={false}>
              <Col flex="none">
                <Space align="center" className="h-100">
                  <EnterOutlined style={{ transform: 'scaleX(-1)' }} />
                </Space>
              </Col>
              <Col flex="auto">
                <div className="ms-3">
                  <ActivityOperationItem id={item.id} values={values} />
                </div>
              </Col>
            </Row>
          ))}
        </div>
      )}
    </div>
  );
}
