import { Avatar, EnumValue, Table, TableColumnType } from '@/components';
import { useGetUsersQuery, UserState } from '@/redux';
import { Space } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { UserStatusBadge } from './UserStatusBadge';
import { useFiltersFactory } from '@/utils';
import { formatters, Role } from '@/core';

function useColumns() {
  const { t } = useTranslation();
  const filters = useFiltersFactory<UserState>();

  return useMemo<TableColumnType<UserState>[]>(
    () => [
      {
        title: t('users.name'),
        key: 'name',
        ...filters.plainText((x) => formatters.name(x)),
        render: (_, { firstName, lastName, avatar, id }) => (
          <Space size="middle">
            <Avatar size={40} reference={avatar} alt={`${firstName} ${lastName}`} />
            <NavLink to={`/users/${id}`}>{`${firstName} ${lastName}`}</NavLink>
          </Space>
        ),
      },
      {
        title: t('users.email'),
        ...filters.plainText((x) => x.email),
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.email.localeCompare(b.email),
        key: 'email',
        dataIndex: 'email',
      },
      {
        title: t('users.role'),
        key: 'role',
        ...filters.enumSelect((x) => x.role, Role),
        render: (_, { role }) => <EnumValue type={Role} value={role} />,
      },
      {
        title: t('users.status.title'),
        key: 'status',
        render: (_, user) => <UserStatusBadge user={user} />,
      },
    ],
    [t, filters],
  );
}

export function UsersListPanel() {
  const columns = useColumns();
  const { data: users, isFetching } = useGetUsersQuery();

  return (
    <Table<UserState> loading={isFetching} columns={columns} dataSource={users} rowKey={(row) => row.id} />
  );
}
