import { FilterRule } from '@webinex/asky';
import { isEmpty } from 'lodash';
import type { ActivitySearchFormValue } from './ActivityTableSearch';

function mapValueFilters(value: ActivitySearchFormValue['valueFilters']): FilterRule | false {
  const filters: FilterRule[] = value
    .filter((x) => !isEmpty(x.path))
    .map((x) => ({
      operator: '=',
      fieldId: `$value.${x.path}`,
      value: x.value,
    }));

  if (isEmpty(filters)) {
    return false;
  }

  return filters.length > 1 ? { operator: 'and', children: filters } : filters[0];
}

export function mapActivitySearchToFilterRule(value: ActivitySearchFormValue): FilterRule {
  const { userId, kind, valueFilters } = value;

  const userIdRule: FilterRule | false = !!userId && {
    operator: '=',
    fieldId: 'userId',
    value: userId,
  };

  const kindRule: FilterRule | false = !!kind && {
    operator: '=',
    fieldId: 'kind',
    value: kind,
  };

  const valueRule: FilterRule | false = mapValueFilters(valueFilters);

  const filters = [userIdRule, kindRule, valueRule].filter((x) => !!x) as FilterRule[];

  return filters.length > 1 ? { operator: 'and', children: filters } : filters[0];
}
