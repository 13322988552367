import { Form, Page } from '@/components';
import { useTranslation } from 'react-i18next';
import { ChangePasswordPanel } from '@/views';
import { useUserContextSelector } from '@/redux';

export function UserChangePasswordPage() {
  const name = useUserContextSelector((x) => x.name);
  const { t } = useTranslation();
  const fallbackTitle = t('fetchFallbackTitle');
  const title = t('users.passwordChange.title');

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href="/me">{name ?? fallbackTitle}</Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          title={title}
          actionsPosition="bottom"
          actions={
            <Form.Submit type="primary" uid="password-change">
              {t('save')}
            </Form.Submit>
          }
        >
          <ChangePasswordPanel />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
