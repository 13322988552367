import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { enumName, EnumObject, EnumValue } from './enumObject';

export function useEnumTranslation() {
  const { t } = useTranslation();
  return useCallback(
    <T extends Readonly<string[]>>(type: EnumObject<T>, value: EnumValue<T>) => {
      return t(`enums.${enumName(type)}.${value}`);
    },
    [t],
  );
}
