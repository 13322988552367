import { Log, User, UserManager } from 'oidc-client';

const LOGIN_REQUIRED_ERROR = 'login_required';
const INFINITE_USER_PROMISE = new Promise<User>(() => {});

class OpenId {
  private _userManagerValue!: UserManager;
  private _user!: User;

  public isAuthenticated = async (): Promise<boolean> => {
    let user = await this.user();

    if (!this.isValid(user!)) {
      user = await this.renewToken({ autoRedirectToSignIn: false });
    }

    return !!user;
  };

  public ensureAuthenticated = async () => {
    try {
      await this.userManager.querySessionStatus();
      return true;
    } catch (ex: any) {
      if (ex?.error === LOGIN_REQUIRED_ERROR) this.userManager.signinRedirect();
      return INFINITE_USER_PROMISE;
    }
  };

  public user = async (): Promise<User | null> => {
    if (this.isValid(this._user)) return this._user;

    this._user = (await this.userManager.getUser())!;
    return this._user;
  };

  public token = async () => {
    const user = await this.getUserOrRedirectToSignIn();
    return user!.access_token;
  };

  public logout = async () => {
    await this.userManager.signoutRedirect();
    await INFINITE_USER_PROMISE;
  };

  public autoLogout = async () => {
    await this.userManager.signoutRedirect({
      extraQueryParams: { autoLogout: true },
    });
  };

  private getUserOrRedirectToSignIn = async () => {
    let user = await this.user();

    if (!this.isValid(user!)) {
      user = await this.renewToken({ autoRedirectToSignIn: true });
    }

    return user;
  };

  private isValid = (user: User) => {
    return !!user && !user.expired;
  };

  public renewToken = async (args: { autoRedirectToSignIn: boolean }): Promise<User> => {
    try {
      return await this.userManager.signinSilent();
    } catch (ex) {
      if (args.autoRedirectToSignIn === true) await this.redirectToSignInIfLoginRequired(ex);

      throw ex;
    }
  };

  public renewRevokedToken = async (): Promise<User> => {
    try {
      return await this.userManager.signinSilent();
    } catch (ex) {
      await this.redirectToSignInIfLoginRequired(ex, { revoked: true });
      throw ex;
    }
  };

  private redirectToSignInIfLoginRequired = async (ex: any, extraQueryParams?: any): Promise<void> => {
    if (ex.error !== LOGIN_REQUIRED_ERROR) {
      return;
    }

    await this.userManager.signoutRedirect({
      extraQueryParams,
    });
    await INFINITE_USER_PROMISE;
  };

  private get userManager(): UserManager {
    if (this._userManagerValue) {
      return this._userManagerValue;
    }

    this._userManagerValue = new UserManager({
      authority: `${window.appSettings.identityServerUrl}/`,
      client_id: 'webapp',
      loadUserInfo: true,
      redirect_uri: `${window.location.origin}/signin-callback.html`,
      silent_redirect_uri: `${window.location.origin}/silent-renew.html`,
      response_type: 'id_token token',
      scope: 'profile openid webapi id.public',
      automaticSilentRenew: true,
    });

    this._userManagerValue.events.addUserSignedOut(() => {
      this._userManagerValue.removeUser();
      this._userManagerValue.signoutRedirect();
    });

    Log.logger = console;
    Log.level = Log.DEBUG;

    return this._userManagerValue;
  }
}

export const openid = new OpenId();
