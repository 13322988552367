import { Icon } from '@/components';
import { Button, Col, Menu, Row, Space } from 'antd';
import { Navigate, ToolbarProps, View, Event, ViewsProps } from 'react-big-calendar';
import styles from '../Calendar.module.scss';
import { useBindArgs } from '@/utils';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { SelectInfo } from 'rc-menu/lib/interface';
import { isArray } from 'lodash';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

function isViewDefined(views: ViewsProps<Event>, view: View) {
  return isArray(views) ? views.includes(view) : !!views[view];
}

function Title(props: ToolbarProps<Event, object>) {
  const { label, onNavigate } = props;
  const gotoNext = useBindArgs(onNavigate, Navigate.NEXT);
  const gotoPrev = useBindArgs(onNavigate, Navigate.PREVIOUS);

  return (
    <Space className={styles.range} size="large">
      <Button
        className={styles.navigation}
        type="link"
        onClick={gotoPrev}
        icon={<Icon type="arrow-left" />}
      />
      <span className={styles.label}>{label}</span>
      <Button
        className={styles.navigation}
        type="link"
        onClick={gotoNext}
        icon={<Icon type="arrow-right" />}
      />
    </Space>
  );
}

function ViewsMenu(props: ToolbarProps<Event, object>) {
  const { onView, view, views } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'calendars' });
  const onSelect = useCallback((value: SelectInfo) => onView(value.selectedKeys.at(0)! as View), [onView]);
  const value = useMemo(() => [view], [view]);
  const items = useMemo<ItemType[]>(
    () =>
      [
        { key: 'week' as const, label: t('week') },
        { key: 'day' as const, label: t('daily') },
        { key: 'month' as const, label: t('month') },
      ].filter((item) => isViewDefined(views, item.key)),

    [t, views],
  );

  return (
    <Menu
      rootClassName={styles.views}
      selectedKeys={value}
      onSelect={onSelect}
      className="nav"
      mode="horizontal"
      disabledOverflow
      items={items}
    />
  );
}

export function CustomToolbar(props: ToolbarProps<Event, object>) {
  const { onNavigate } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'calendars' });
  const gotoToday = useBindArgs(onNavigate, Navigate.TODAY);

  return (
    <Row className={styles.toolbar} align="middle" justify="space-between">
      <Col>
        <div className={styles.actions}>
          <Button className={styles.today} onClick={gotoToday} type="ghost">
            {t('today')}
          </Button>
        </div>
      </Col>
      <Col>
        <Title {...props} />
      </Col>
      <Col>
        <ViewsMenu {...props} />
      </Col>
    </Row>
  );
}
