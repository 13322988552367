import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import AntIcon, { DeleteOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import { IconType } from './IconType.auto-created';
import { IconMap as SvgIconMap } from './IconMap.auto-created';
import classNames from 'classnames';

const ICON_MAP = {
  add: PlusOutlined,
  delete: DeleteOutlined,
  check: CheckOutlined,
};

const ICON_MAP_VALUES = ICON_MAP as Record<string, typeof AntIcon>;

export interface IconProps extends Omit<Partial<CustomIconComponentProps>, 'component'> {
  type: IconType | keyof typeof ICON_MAP;
}

export function Icon({ type: iconType, ...props }: IconProps) {
  const Icon = ICON_MAP_VALUES[iconType];
  const className = classNames(props.className, `icon-${Icon}`);

  if (Icon) {
    return <Icon className={className} {...props} />;
  }

  return <AntIcon component={SvgIconMap[iconType as IconType]} {...props} className={className} />;
}
