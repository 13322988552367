import { useEffect, useMemo, useRef, useState } from 'react';
import { flippoClient } from '@/core';
import { Avatar } from '@/components';
import { LazyAvatarCropper } from './LazyAvatarCropper';
import { Picker } from './AvatarPicker';

import styles from './UserAvatarEdit.module.scss';

export interface UserAvatarEditProps {
  fullName: string;
  reference?: string;
  onChange(reference: string): any;
}

function useBlob(file: File | undefined) {
  const previousBlobRef = useRef<string | undefined>(undefined);
  useEffect(() => cleanup, []);

  function cleanup() {
    previousBlobRef.current && URL.revokeObjectURL(previousBlobRef.current);
  }

  return useMemo(() => {
    cleanup();
    return file && (previousBlobRef.current = URL.createObjectURL(file));
  }, [file]);
}

function useAvatarState(props: UserAvatarEditProps) {
  const { onChange } = props;
  const [file, setFile] = useState<File | undefined>(undefined);
  const blob = useBlob(file);

  const onCancel = () => {
    setFile(undefined);
  };

  const onSave = (file: File) =>
    flippoClient
      .store(file)
      .then(onChange)
      .then(() => setFile(undefined));

  return { blob, onSelect: setFile, onCancel, onSave };
}

export function UserAvatarEdit(props: UserAvatarEditProps) {
  const { fullName, reference } = props;
  const { blob, onSelect, onCancel, onSave } = useAvatarState(props);

  return (
    <div className={styles.container}>
      <Avatar size="lg" alt={fullName} reference={reference} zoom />
      <Picker onSelected={onSelect} />
      {blob && <LazyAvatarCropper blob={blob} onCancel={onCancel} onSave={onSave} />}
    </div>
  );
}
