import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Icon, Page } from '@/components';
import { ActivityDetailsPanel } from '@/views';

const useId = () => {
  const { id } = useParams<'id'>();
  return id!;
};

export function ActivityDetailsPage() {
  const id = useId();
  const { t } = useTranslation();

  return (
    <Page htmlTitle={t('activity.details.title')}>
      <Page.Paths>
        <Page.Path href="/activity">{t('activity.title')}</Page.Path>
        <Page.Path>{t('activity.details.title')}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card icon={<Icon type="template" />} color="#0097a7" title={t('activity.details.title')}>
          <ActivityDetailsPanel id={id} />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
